import React, { Children } from 'react';
import Layout, { Content } from 'antd/lib/layout/layout';
import { Col, Flex, Row } from 'antd';
import styles from './Datas.module.css';
import RequestComponent from '../../compontents/common/RequestComponent';
import DashboardFullView from './DashboardFullView';

export default class DashboardDatas extends React.Component {
    constructor(props) {
        super(props);
        // this.login = this.isLogin(); // 登录信息
        this.isTime = true;
        this.state = {
            data: {},
            display: 1
        }
    }
    componentDidMount = () => {
        this.query();
    }

    getRandomInt = (r1, r2) => {
        // 计算范围的宽度  
        let range = r2 - r1 + 1;  
        
        // 生成并映射随机数  
        let randomNumber = Math.floor(Math.random() * range) + parseInt(r1);  
        return randomNumber; 
    }

    query = (data) => {
        const that = this;
        RequestComponent('public/config', {
            method: "GET",
            data: data
        }).then(r => {
            if (r.data.data != null) {
                this.setState({
                    data: r.data.data
                });
                this.updateData(r)
                setInterval(() => {
                    that.setState({
                        'display' : this.state.display == 1 ? 2 : 1
                    });
                }, r.data.data.tab_second * 1000);
            }
        })
    }

    updateData = (r) => {
        const that = this;
        setInterval(function() {
            let people = 0;
            if (r.data.data.tourist_second != "" && r.data.data.tourist_second != 0) {
                const range = r.data.data.tourist_range.split(',');
                people = that.getRandomInt(range[0],range[1]);
            }
            RequestComponent('public/config', {
                method: "GET",
                data: {
                    people: people,
                    method: 'update'
                }
            }).then(r => {
                if (r.data.data != null) {
                    that.setState({
                        data: r.data.data
                    });
                }
            })
        },this.state.data.tourist_second * 1000);
    }

    // 是否登录
    isLogin() {
        let login = JSON.parse(localStorage.getItem("login"))
        if (!login || Date.parse(new Date()) / 1000 - login.admin.LoginTime > 7 * 86400) {
            return window.location.href = "/login"
        }
        return login
    }


    render() {
        let element = "";
            element = (
                <div>
                    {/* 全景大屏 */}
                    {this.state.display == 2 &&
                        <DashboardFullView/>
                    }


                    {/* 智慧大屏 */}
                    <div style={{
                        height: '100vh',
                        width: '100vw',
                        display: this.state.display == 1 ? 'block' : 'none'
                    }}>
                        <div style={{
                            zIndex: 999,
                            color: 'white',
                            fontSize: '32px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            position: 'absolute',
                            left: '37vw',
                            top: '8.5vh'
                        }}>红杨旅游度假区智慧大屏</div>
                        <img className={styles['bg']} src='https://kkk.hongyangtour.com/image/common/datasbg.jpg'></img>
                        <Flex  className={styles['data-flex1']}>
                            <div className={styles['data-div']}>
                                <div  className={styles['data-div-number']}>{this.state.data.data1}</div>
                                <img src='/data1.png'  className={styles['data-div-image']} />
                                <div className={styles['data-div-desc']}>A级景点</div>
                            </div>
                            <div className={styles['data-div']}>
                                <div  className={styles['data-div-number']}>{this.state.data.data2}</div>
                                <div><img src='/data1.png' className={styles['data-div-image']} /></div>
                                <div className={styles['data-div-desc']}>景点</div>
                            </div>
                            <div className={styles['data-div']}>
                                <div  className={styles['data-div-number']}>{this.state.data.data3}</div>
                                <div><img src='/data1.png' className={styles['data-div-image']} /></div>
                                <div className={styles['data-div-desc']}>乡村旅游点</div>
                            </div>
                        </Flex>

                        <Flex  className={styles['data-flex2']}>
                            <div className={styles['data-div']}>
                                <div  className={styles['data-div-number']}>{this.state.data.data4}</div>
                                <img src='/data2.png' className={styles['data-div-image']}/>
                                <div className={styles['data-div-desc']}>酒店</div>
                            </div>
                            <div className={styles['data-div']}>
                                <div  className={styles['data-div-number']}>{this.state.data.data5}</div>
                                <div><img src='/data2.png' className={styles['data-div-image']} /></div>
                                <div className={styles['data-div-desc']}>民宿</div>
                            </div>
                            <div className={styles['data-div']}>
                                <div  className={styles['data-div-number']}>{this.state.data.data6}</div>
                                <div><img src='/data2.png' className={styles['data-div-image']}/></div>
                                <div className={styles['data-div-desc']}>特色餐饮</div>
                            </div>
                        </Flex> 

                        <Flex  className={styles['data-flex3']}>
                            <div className={styles['data-div']}>
                                <div  className={styles['data-div-number']}>{this.state.data.data15}</div>
                                <img src='/data3.png' className={styles['data-div-image']}/>
                                <div className={styles['data-div-desc']}>五星房车露营地</div>
                            </div>
                            <div className={styles['data-div']}>
                                <div  className={styles['data-div-number']}>{this.state.data.data7}</div>
                                <img src='/data3.png' className={styles['data-div-image']}/>
                                <div className={styles['data-div-desc']}>停车场</div>
                            </div>
                            <div className={styles['data-div','data-div2']}>
                                <div  className={styles['data-div-number']}>{this.state.data.data8}</div>
                                <div><img src='/data3.png' className={styles['data-div-image']} /></div>
                                <div className={styles['data-div-desc']}>旅游厕所</div>
                            </div>
                        </Flex> 

                        <Flex  className={styles['data-flex4']}>
                            <div className={styles['data-div']}>
                                <div  className={styles['data-div-number2']}>{this.state.data.data9}</div>
                                <img src='/data-time.png'  className={styles['data-div-image']} />
                                <div className={styles['data-div-desc']}>实时游客量</div>
                            </div>
                            <div className={styles['data-div']}>
                                <div  className={styles['data-div-number2']}>{this.state.data.data10}</div>
                                <div><img src='/data-week.png' className={styles['data-div-image']} /></div>
                                <div className={styles['data-div-desc']}>周游客量</div>
                            </div>
                            <div className={styles['data-div']}>
                                <div  className={styles['data-div-number2']}>{this.state.data.data11}</div>
                                <div><img src='/data-1y.png' className={styles['data-div-image']} /></div>
                                <div className={styles['data-div-desc']}>年累计游客量</div>
                            </div>
                        </Flex>

                        <Flex  className={styles['data-flex5']}>
                            <div className={styles['data-div']}>
                                <div  className={styles['data-div-number3']}>{this.state.data.data12}</div>
                                <img src='/data-man.png'  className={styles['data-div-image']} />
                                <div className={styles['data-div-desc']}>男性人数</div>
                            </div>
                            <div className={styles['data-div']}>
                                <div  className={styles['data-div-number3']}>{this.state.data.data13}</div>
                                <div><img src='/data-woman.png' className={styles['data-div-image']} /></div>
                                <div className={styles['data-div-desc']}>女性人数</div>
                            </div>
                            <div className={styles['data-div']}>
                                <div  className={styles['data-div-number3']}>{this.state.data.data14}</div>
                                <div><img src='/data-ticket.png' className={styles['data-div-image']} /></div>
                                <div className={styles['data-div-desc']}>预定购票</div>
                            </div>
                        </Flex>
                    </div>
                </div>
            );
        return (
            element
        )
    }
}