import React from 'react';
import { Button, Form, Input, Row, Table, Layout, Col, Space, Divider, Select, Popconfirm, Card, Modal, message, Tree, Switch, Alert } from 'antd';
import RequestComponent from '../../../compontents/common/RequestComponent';
import { timeToDateTime } from '../../../compontents/common/DateComponent'
import { formlayout, formlayoutModal, getRowClassName, searchFormCol } from "../../../global";
import {ReloadOutlined }  from '@ant-design/icons';

export default class Node extends React.Component {
    constructor(props) {
        super(props);
        this.formModalRef = React.createRef();
        this.initialValues = this.getInitialValues();
        this.state = {
            data: [],
            node: [],
            modalTitle: '添加',
            validate: {
                node_controller: {}
            },
        }
    }

    componentDidMount = () => {
        this.queryData();
    };

    getInitialValues = () => {
        return {
            node_name: '',
            node_controller: '',
            node_action: '',
            is_alert: 0,
            node_pid: '0',
            id: 0
        };
    }

    queryData = () => {
        RequestComponent('system/node', {
            method: "GET",
            data: {}
        }).then(r => {
            let nodes = r.data.data.node;
            let data = [];
            for (let item in nodes) {
                if (nodes[item].NodePid == 0) {
                    let node = {
                        key: nodes[item].NodeId,
                        NodeId: nodes[item].NodeId,
                        NodeName: nodes[item].NodeName,
                        IsAlert: nodes[item].IsAlert,
                        NodeUrl: nodes[item].NodeUrl ? nodes[item].NodeUrl : '空',
                        children: []
                    };
                    for (let item in nodes) {
                        let nodec = {
                            key: nodes[item].NodeId,
                            NodeId: nodes[item].NodeId,
                            NodePid: nodes[item].NodePid,
                            IsAlert: nodes[item].IsAlert,
                            NodeName: nodes[item].NodeName,
                            NodeUrl: nodes[item].NodeUrl ? nodes[item].NodeUrl : '空',
                            children: []
                        };
                        if (nodec.NodePid == node.NodeId) {
                            for (let item in nodes) {
                                let nodecc = {
                                    key: nodes[item].NodeId,
                                    NodeId: nodes[item].NodeId,
                                    NodePid: nodes[item].NodePid,
                                    IsAlert: nodes[item].IsAlert,
                                    NodeName: nodes[item].NodeName,
                                    NodeUrl: nodes[item].NodeUrl ? nodes[item].NodeUrl : '空',
                                    children: []
                                };
                                if (nodecc.NodePid == nodec.NodeId) {
                                    nodec.children.push(nodecc)
                                }
                            }
                            node.children.push(nodec);
                        }
                    }
                    data.push(node);
                }
            }
            this.setState({
                data: data
            });
        });
    };



    // 显示弹窗
    showModal = (e) => {
        let id = e.currentTarget.dataset.id || 0;
        let data = {
            id: id
        };
        RequestComponent('system/nodeAdd', {
            method: "GET",
            data: data
        }).then(r => {
            let node = r.data.data.node;
            let nodes = []
            for (let item in node) {
                let n = node[item];
                if (n.NodePid == 0) {
                    n["key"] = n.NodeId;
                    n.NodeName = '------' + n.NodeName;
                    nodes.push(n)
                    for (let item in node) {
                        let nc = node[item]
                        nc["key"] = nc.NodeId;
                        if (nc.NodePid == n.NodeId) {
                            nc.NodeName = '------------' + nc.NodeName;
                            nodes.push(nc)
                            for (let item in node) {
                                let ncc = node[item]
                                ncc["key"] = ncc.NodeId;
                                if (ncc.NodePid == nc.NodeId) {
                                    ncc.NodeName = '------------------' + ncc.NodeName;
                                    nodes.push(ncc)
                                }
                            }
                        }
                    }
                }
            }
            this.setState({
                node: nodes,
                openModal: true
            });
            if (r.data.data.data.NodeId > 0) {
                this.formModalRef.current.setFieldsValue({
                    id: r.data.data.data.NodeId,
                    node_name: r.data.data.data.NodeName,
                    node_pid: r.data.data.data.NodePid,
                    node_action: r.data.data.data.NodeAction
                });
            }
        });
    }

    // 关闭弹窗
    closeModal = () => {
        this.formModalRef.current.setFieldsValue(this.initialValues);
        this.setState({
            openModal: false
        });
    }

    // 重置查询
    reload = () => {
        this.queryData();
    }

    onCheck = (checkedKeys, info) => {
        this.state.checkedKeys = checkedKeys;
        this.formModalRef.current.setFieldsValue({
            node_keys: this.state.checkedKeys.checked.join(',')
        });
    }

    // 表单提交
    onFinish = (v) => {
        if (v.node_pid == 0) {
            this.state.validate.node_controller = {
                validateStatus: 'error',
                help: '根节点控制器必填'
            };
            this.setState({
                validate: this.state.validate
            });
            return
        }
        RequestComponent('system/groupAdd', {
            data: v
        }).then(r => {
            if (r.data.code == 0) {
                let logText = '添加管理组：' + v.group_name;
                if (v.id != undefined) {
                    logText = '编辑管理组：' + v.group_name;
                }
                RequestComponent('log/add', {
                    data: {
                        content: logText
                    }
                });
                message.success({
                    content: '提交成功',
                    style: {
                        marginTop: '10vh',
                    },
                });
                this.closeModal();
                this.reload();
            }
        })
    }

    /**
     * 删除
     * @param {*} v 
     */
    onDel = (v) => {
        if (v.NodeId > 0) {
            RequestComponent('system/nodeDelete', {
                data: {
                    id: v.NodeId
                }
            }).then(r => {
                if (r.data.code == 0) {
                    RequestComponent('log/add', {
                        data: {
                            content: '删除权限节点：' + v.NodeName
                        }
                    });
                    document.querySelector('.ant-table-row[data-row-key="' + v.key + '"]').remove();
                }
            });
        }
    }


    render() {
        let columns = [
            {
                title: '节点名称',
                dataIndex: 'NodeName',
            },
            {
                title: '节点URL',
                dataIndex: 'NodeUrl',
            },
            {
                title: '操作',
                render: (v) => {
                    return (
                        <Row>
                            {v.IsAlert == 1 && this.props.nodeKeys["system/nodeAdd"] &&
                                <Button type="primary" size="small" data-id={v.NodeId} onClick={this.showModal}>编辑</Button>
                            }
                            &nbsp;
                            {v.IsAlert == 1 && this.props.nodeKeys["system/nodeDelete"] &&
                                <Popconfirm title="确定删除?" onConfirm={() => this.onDel(v)}>
                                    <Button type="primary" danger size="small" >删除</Button>
                                </Popconfirm>
                            }
                        </Row>
                    )
                }
            }
        ];
        let html = (
            <Layout.Content>
                {this.props.nodeKeys["system/nodeAdd"] &&
                    <Button type="primary" style={{marginBottom: 20}} onClick={this.showModal}>添加</Button>
                }
                {this.state.data.length > 0 &&
                    <Table columns={columns} dataSource={this.state.data} defaultExpandAllRows={true} size="small" scroll={{ x: 'max-content' }} pagination={false} ></Table>
                }

                <Modal title={this.state.modalTitle}  open={this.state.openModal} onCancel={this.closeModal} width={600} footer={null} forceRender={true}>
                    <Form {...formlayoutModal.form} onFinish={this.onFinish} ref={this.formModalRef} initialValues={this.initialValues}>
                        <Form.Item label="节点名称" name="node_name" rules={[{ required: true }]} >
                            <Input></Input>
                        </Form.Item>
                        <Form.Item label="上级节点" name="node_pid" rules={[{ required: true }]}>
                            {this.state.node.length > 0 &&
                                <Select showSearch >
                                    <Select.Option key="0" value="0">根节点</Select.Option>
                                    {this.state.node.map((i, e) => (
                                        <Select.Option key={i.NodeId} value={i.NodeId}>{i.NodeName}</Select.Option>
                                    ))}
                                </Select>
                            }
                        </Form.Item>
                        <Form.Item label="节点控制器" name="node_controller" {...this.state.validate.node_controller}>
                            <Input></Input>
                        </Form.Item>
                        <Form.Item label="节点方法" name="node_action" >
                            <Input></Input>
                        </Form.Item>
                        <Form.Item label="可编辑" name="is_alert" >
                            <Switch defaultChecked={true} onChange={this.onChange}></Switch>
                        </Form.Item>
                        <Form.Item hidden name="id">
                            <Input  />
                        </Form.Item>
                        <Form.Item wrapperCol={formlayoutModal.button}>
                            <Button htmlType="submit" type="primary">提交</Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </Layout.Content>
        );
        return (
            html
        );
    }
}