import React from 'react';
import { Button, Form, Input, Row, Table, Layout, Radio, Space, Divider, Select, Popconfirm, Card, Modal, message, Tag, Upload, Checkbox } from 'antd';
import RequestComponent from '../../../compontents/common/RequestComponent';
import { timeToDateTime } from '../../../compontents/common/DateComponent'
import { formlayout, formlayoutModal, getRowClassName, ossUrl, searchFormCol } from "../../../global";
import {ReloadOutlined, LoadingOutlined, PlusOutlined }  from '@ant-design/icons';
import BraftEditor from 'braft-editor';
import NoticeAdd from './NoticeAdd';

export default class Notice extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.formModalRef = React.createRef();
        this.initialValues = this.getInitialValues();
        // 查询参数
        this.queryParam = {
            page: 1,
            limit: 20,
            category_id: 0,
            notice_type: 0,
        };
        this.state = {
            modalTitle: '添加',
            openModal: false,
            id:0,
            data: [],
            category: [],
            scenics: [],
            categorys: {},
            loading: false,
            imageUrl: '',
            flag: 1,
            editorState: BraftEditor.createEditorState(null),
            queryParam: {
                page: 1,
                limit: 20,
                category_id: 0,
                notice_type: 0,
            },
            pagination: {
                current: 1,
                position: ["bottomLeft"],
                hideOnSinglePage: true,
                total: 0,
                showQuickJumper: true,
                pageSize: this.queryParam.limit,
                showSizeChanger: false,
                showTotal: (total) => {return '共' + total + '条'}
            }
        }
    }

    componentDidMount = () => {
        this.queryData();
    };

    getInitialValues = () => {
        return {
            status: 1,
            type: 1,
            name: '',
            appid: '',
            appsecret: '',
            token: '',
            mchid: '',
            paykey: '',
            certificate_no: '',
            private_key: '',
            notify_url: '',
            refund_notify_url: '',
            subscribe_content:'',
            imageUrl: '',
            id: 0
        };
    }

    queryData = (data) => {
        if (data == undefined) {
            data = this.queryParam;
        }
        RequestComponent('operate/notice', {
            method: "GET",
            data: data,
        }).then(r => {
            if (r.data.code == 0) {
                r.data.data.categorys.forEach(element => {
                    this.state.categorys[element.CategoryId] = element;
                });
                this.state.pagination.total = r.data.data.count;
                this.setState({
                    data: r.data.data.list,
                    pagination: this.state.pagination,
                    category: r.data.data.categorys
                });
            }
        });
    };

    handleTableChange = (page, filter, sort) => {
        let data = this.formRef.current.getFieldsValue();
        if (page.current != this.state.pagination.current) {
            this.state.pagination.current = page.current;
            // this.state.queryParam.page = page.current
            data.page = page.current;
        }
        this.queryData(data)
    };

    // 表单提交
    submit = (v) => {
        this.queryData(v)
    }

    // 重置查询
    reload = () => {
        if (this.state.pagination.current > 1) {
            this.formRef.current.setFieldsValue({
                page: this.state.pagination.current
            });
        }
        this.formRef.current.submit();
        // this.onReset();

    }

    // 重置表单
    onReset = (v) => {
        this.formRef.current.setFieldsValue(this.queryParam);
    }

    // 显示弹窗
    showModal = (e) => {
        let id = e.currentTarget.dataset.id || 0;
        let data = {
            openModal: true,
            id: id,
        };
        this.setState(data);
    }

    // 关闭弹窗
    closeModal = () => {
        // this.formModalRef.current.setFieldsValue(this.initialValues);
        this.setState({
            openModal: false,
            imageUrl: ''
        });
    } 

    // 表单提交
    onFinish = (v) => {
        if (v.name != '') {
            RequestComponent('wechat/officialAdd', {
                data: v
            }).then(r => {
                if (r.data.code == 0) {
                    let logText = '添加微信号：' + v.Name;
                    if (v.CategoryId != undefined) {
                        logText = '编辑微信号：' + v.Name;
                    }
                    RequestComponent('log/add', {
                        data: {
                            content: logText
                        }
                    });
                    message.success({
                        content: '提交成功',
                        style: {
                            marginTop: '10vh',
                        }
                    });
                    this.closeModal();
                    this.reload();
                } else {
                    message.error({
                        content: r.data.msg,
                        style: {
                            marginTop: '10vh',
                        },
                    });
                }
            });
        }
    }

    /**
     * 删除
     * @param {*} v
     */
    onDel = (v) => {
        RequestComponent('operate/noticeDelete', {
            data: {
                id: v.id
            }
        }).then(r => {
            if (r.data.code == 0) {
                RequestComponent('log/add', {
                    data: {
                        content: '删除资讯：' + v.title
                    }
                });
                this.setState({
                    data: this.state.data.filter(item => item.id != v.id)
                });
            } else {
                message.error({
                    content: r.data.msg,
                    style: {
                        marginTop: '10vh',
                    }
                });
            }
        });
    };

    onType = (v) => {
        if (v.target.value == 1) {
            this.setState({
                wx: {
                    official: true,
                    mini: false,
                    pay: false
                }
            })
        } else if (v.target.value == 2) {
            this.setState({
                wx: {
                    official: false,
                    mini: true,
                    pay: false
                }
            })
        } else if (v.target.value == 3) {
            this.setState({
                wx: {
                    official: false,
                    mini: false,
                    pay: true
                }
            })
        }
    }

    /**
     * 上传之前
     * @param {*} v 
     */
    beforeUpload = function (file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('只能上传 JPG/PNG 格式文件!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('图片不得大于 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    /**
     * 上传图片
     * @param {*} info 
     */
    onChangeUpload = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
        }
        if (this.state.loading) {
            this.state.loading = false;
            this.getBase64(info.file.originFileObj, imageUrl => {
                this.setState({
                    imageUrl: imageUrl,
                    loading: false,
                });
                this.formRef.current.setFieldsValue({
                    imageUrl: imageUrl
                });
            }
            );
        }
        if (info.file.status == undefined) {
            this.formRef.current.setFieldsValue({
                file: undefined
            });
        }
        // if (info.file.status === 'done') {
        //     // Get this url from response in real world.
        //     this.getBase64(info.file.originFileObj, imageUrl =>
        //         this.setState({
        //             imageUrl: imageUrl,
        //             loading: false,
        //         }),
        //     );
        // }
    };
    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }


    render() {
        let columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                align: 'center',
            },
            {
                title: '标题',
                dataIndex: 'title',
                align: 'center',
            },
            {
                title: '创建时间',
                dataIndex: 'create_time',
                align: 'center',
                render: (v) => {
                    return timeToDateTime(v);
                }
            },
            {
                title: '排序',
                dataIndex: 'sort',
                align: 'center'
            },
            {
                title: '分类',
                dataIndex: 'category_name',
                align: 'center',
                render: (v) => {
                    if (v != '' ) {
                        return <Tag color='#fbb417'>{v}</Tag>;
                    } else {
                        return "无";
                    }
                }
            },
            {
                title: '类型',
                dataIndex: 'notice_type',
                align: 'center',
                render: (v) => {
                    if (v == 1) {
                        return <Tag bordered={false} color='error'>文章</Tag>
                    } else {
                        return <Tag bordered={false} color='success'>banner</Tag>
                    }
                }
            },
            {
                title: '是否展示',
                dataIndex: 'flag',
                align: 'center',
                render: (v) => {
                    if (v == 0) {
                        return <Tag bordered={false} color='error'>隐藏</Tag>
                    } else {
                        return <Tag bordered={false} color='success'>展示</Tag>
                    }
                }
            },
            {
                title: '操作',
                dataIndex: 'id',
                render: (v, r) => {
                    return (
                        <Row>
                            <Space size={10}>
                                {this.props.nodeKeys["operate/noticeAdd"] &&
                                    <Button type="primary" size="small" data-id={v} onClick={this.showModal}>编辑</Button>
                                }
                                {this.props.nodeKeys["operate/noticeDelete"] &&
                                    <Popconfirm title="确定删除?" onConfirm={() => this.onDel(r)}>
                                        <Button type="primary" danger size="small" >删除</Button>
                                    </Popconfirm>
                                }
                            </Space>
                        </Row>
                    )
                }
            }
        ];

        let uploadButton = (
            <div>
                {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>上传封面</div>
            </div>
        );

        let html = (
            <Layout.Content>
                <Form name="submit-form" layout="inline" ref={this.formRef} onFinish={this.submit} initialValues={this.queryParam}>
                    <Form.Item label="类型" name="notice_type">
                        <Select style={{width: 120,}}>
                            <Select.Option key={0} value={0}>全部</Select.Option>
                            <Select.Option key={1} value={1}>文章</Select.Option>
                            <Select.Option key={2} value={2}>banner</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="分类" name="category_id">
                        {this.state.category.length > 0 &&
                            <Select style={{width: 120,}}>
                                <Select.Option key={0} value={0}>全部</Select.Option>
                                {this.state.category.map((i, e) => (
                                    <Select.Option key={i.CategoryId} value={i.CategoryId}>{i.CategoryName}</Select.Option>
                                ))}
                            </Select>
                        }
                    </Form.Item>
                    <Form.Item hidden name='page'>
                        <Input />
                    </Form.Item>
                    <Form.Item hidden name='limit'>
                        <Input />
                    </Form.Item>
                    <Form.Item className="search-button-item">
                        <Space size={10}>
                            <Button htmlType="submit" type="primary">查询</Button>
                            <Button htmlType="reset" type="default" onClick={this.onReset}>清空</Button>
                            <Button shape="circle" icon={<ReloadOutlined />} onClick={this.reload}></Button>
                        </Space>
                    </Form.Item>
                </Form>
                <Divider />
                {this.props.nodeKeys['operate/categoryAdd'] && 
                    <Button type="primary" style={{marginBottom: 20}} onClick={this.showModal}>添加</Button>
                }
                <Table rowClassName={getRowClassName} rowKey="id" columns={columns} dataSource={this.state.data} scroll={{ x: 'max-content' }} pagination={this.state.pagination} size="small" onChange={this.handleTableChange}></Table>

                <Modal title={this.state.modalTitle}  open={this.state.openModal} onCancel={this.closeModal} width={1000} footer={null} forceRender={true} maskClosable={false}>
                    {this.state.openModal &&
                        <NoticeAdd id={this.state.id} parent={this} />
                    }
                </Modal>
            </Layout.Content>
        );
        return (
            html
        );
    }
}