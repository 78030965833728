import React from 'react';
import { Result, Button } from 'antd';

export class Error404 extends React.Component {
    constructor(props) {
        super(props);

    }

    goback() {
        window.history.back();
    }

    render() {
        
        let html = (
            <Result
                status="404"
                title="404"
                subTitle="页面不存在"
                extra={<Button type="primary" onClick={this.goback}>返回上一页</Button>}
            />
        );
        return (
            html
        )
    }
}