import React from 'react';
import { Button, Form, Input, Row, Table, Layout, Col, Space, Divider, Select, Popconfirm, Card, Modal, message, Upload } from 'antd';
import RequestComponent from '../../../compontents/common/RequestComponent';
import { timeToDateTime } from '../../../compontents/common/DateComponent'
import { apiUrl, formlayout, formlayoutModal, getRowClassName, ossUrl, searchFormCol } from "../../../global";
import {LoadingOutlined, PlusOutlined, ReloadOutlined }  from '@ant-design/icons';
import ScenicTicketAdd from './ScenicTicketAdd';

export default class ScenicTicket extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        // 查询参数
        this.queryParam = {
            page: 1,
            limit: 20,
        };
        this.state = {
            data: [],
            id: 0,
            openModal: false,
            modalTitle: '添加',
            coverImageUrl: '',
            loading: false,
            loading2: false,
            fileList: [],
            imagesKey: [],
            pagination: {
                current: 1,
                position: ["bottomLeft"],
                hideOnSinglePage: true,
                total: 0,
                showQuickJumper: true,
                pageSize: this.queryParam.limit,
                showSizeChanger: false,
                showTotal: (total) => {return '共' + total + '条'}
            }
        }
    }

    componentDidMount = () => {
        this.queryData();
    };

    queryData = (data) => {
        if (data == undefined) {
            data = this.queryParam;
        }
        RequestComponent('scenic/ticket', {
            method: "GET",
            data: data,
        }).then(r => {
            if (r.data.code == 0) {
                this.state.pagination.total = r.data.data.count;
                this.setState({
                    data: r.data.data.list,
                    pagination: this.state.pagination
                });
            }
        });
    };

    // 表格变更
    handleTableChange = (page, filter, sort) => {
        let data = this.formRef.current.getFieldsValue();
        if (page.current != this.state.pagination.current) {
            this.state.pagination.current = page.current;
            data.page = page.current;
        }
        if (sort.field != undefined && sort.order != undefined) {
            data.sort = sort.field + '-' + sort.order;
        }
        this.queryData(data)
    };

    // 表单提交
    submit = (v) => {
        this.queryData(v)
    }

    // 重置查询
    reload = () => {
        if (this.state.pagination.current > 1) {
            this.formRef.current.setFieldsValue({
                page: this.state.pagination.current
            });
        }
        this.formRef.current.submit();
    }

    // 重置表单
    onReset = (v) => {
        this.formRef.current.setFieldsValue(this.queryParam);
    }

    // 显示弹窗
    showModal = (e) => {
        let id = e.currentTarget.dataset.id || 0;
        let data = {
            id: id,
            openModal: true
        };
        this.setState(data);
    }

    // 关闭弹窗
    closeModal = () => {
        this.setState({
            openModal: false
        });
    }

    /**
     * 删除
     * @param {*} v 
     */
    onDel = (v) => {
        RequestComponent('scenic/scenicDelete', {
            data: {
                id: v.id,
                isDel: 1
            }
        }).then(r => {
            if (r.data.code == 0) {
                RequestComponent('log/add', {
                    data: {
                        content: '删除门票：' + v.title
                    }
                });
                this.setState({
                    data: this.state.data.filter(item => item.id != v.id)
                });
            } else {
                message.error({
                    content: r.data.msg,
                    style: {
                        marginTop: '10vh',
                    }
                });
            }
        });
    }

    render() {
        let columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                align: 'center',
                width: 100
            },
            {
                title: '创建时间',
                dataIndex: 'create_time',
                align: 'center',
                width: 250,
                render: (v) => {
                    return timeToDateTime(v);
                }
            },
            {
                title: '名称',
                dataIndex: 'name',
                align: 'center'
            },
            {
                title: '购买数量',
                dataIndex: 'sale_count',
                align: 'center',
                width: 100,
            },
            {
                title: '购买规则',
                dataIndex: 'buy_rule',
                align: 'center',
            },
            {
                title: '退款规则',
                dataIndex: 'refund_rule',
                align: 'center',
            },
            {
                title: '操作',
                render: (v) => {
                    return (
                        <Row>
                            <Space size={10}>
                                {this.props.nodeKeys["scenic/ticketAdd"] &&
                                    <Button type="primary" size="small" data-id={v.id} onClick={this.showModal}>编辑</Button>
                                }
                                {this.props.nodeKeys["scenic/ticketDelete"] &&
                                    <Popconfirm title="确定删除?" onConfirm={() => this.onDel(v)}>
                                        <Button type="primary" danger size="small" >删除</Button>
                                    </Popconfirm>
                                }
                            </Space>
                        </Row>
                    )
                }
            }
        ];

        let uploadButton = (
            <div>
                {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>上传封面</div>
            </div>
        );

        let uploadButton2 = (
            <div>
                {this.state.loading2 ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>上传图集</div>
            </div>
        );


        let html = (
            <Layout.Content>
                <Form name="submit-form" layout="inline" ref={this.formRef} onFinish={this.submit} initialValues={this.queryParam}>
                    <Form.Item hidden name='page'>
                        <Input />
                    </Form.Item>
                    <Form.Item hidden name='limit'>
                        <Input />
                    </Form.Item>
                </Form>
                <Space size={10}>
                    {this.props.nodeKeys['scenic/scenicAdd'] && 
                        <Button type="primary" style={{marginBottom: 20}} onClick={this.showModal}>添加</Button>
                    }
                    <Button shape="circle" icon={<ReloadOutlined />} style={{marginBottom: 20}} onClick={this.reload}></Button>
                </Space>
                <Table rowClassName={getRowClassName} rowKey="id" columns={columns} dataSource={this.state.data} scroll={{ x: 'max-content' }} pagination={this.state.pagination} size="small" onChange={this.handleTableChange}></Table>

                <Modal title={this.state.modalTitle}  open={this.state.openModal} onCancel={this.closeModal} width={1000} footer={null} forceRender={true} maskClosable={false}>
                    {this.state.openModal &&
                        <ScenicTicketAdd id={this.state.id} closeModal={this.closeModal} reload={this.reload} />
                    }
                </Modal>
            </Layout.Content>
        );
        return (
            html
        );
    }
}