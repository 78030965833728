import React from 'react';
import { Button, Form, Input, Row, Table, Layout, Radio, Space, Divider, Select, Popconfirm, Card, Modal, message, Tag, Upload } from 'antd';
import RequestComponent from '../../../compontents/common/RequestComponent';
import { timeToDateTime } from '../../../compontents/common/DateComponent'
import { formlayout, formlayoutModal, getRowClassName, ossUrl, searchFormCol } from "../../../global";
import {ReloadOutlined, LoadingOutlined, PlusOutlined }  from '@ant-design/icons';

export default class Official extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.formModalRef = React.createRef();
        this.initialValues = this.getInitialValues();
        // 查询参数
        this.queryParam = {
            page: 1,
            type: '',
            limit: 20
        };
        this.state = {
            data: [],
            openModal: false,
            modalTitle: '添加',
            loading: false,
            imageUrl: '',
            wx: {
                official: true,
                mini: false,
                pay: false
            },
            pagination: {
                current: 1,
                position: ["bottomLeft"],
                hideOnSinglePage: true,
                total: 0,
                showQuickJumper: true,
                pageSize: 20,
                showSizeChanger: false,
                showTotal: (total) => {return '共' + total + '条'}
            }
        }
    }

    componentDidMount = () => {
        this.queryData();
    };

    getInitialValues = () => {
        return {
            status: 1,
            type: 1,
            name: '',
            appid: '',
            appsecret: '',
            token: '',
            mchid: '',
            paykey: '',
            certificate_no: '',
            private_key: '',
            notify_url: '',
            refund_notify_url: '',
            subscribe_content:'',
            imageUrl: '',
            id: 0
        };
    }

    queryData = (data) => {
        if (data == undefined) {
            data = this.queryParam;
        }
        RequestComponent('wechat/official', {
            method: "GET",
            data: data
        }).then(r => {
            this.state.pagination.total = r.data.data.count
            this.state.data = r.data.data.list
            this.setState({
                data: r.data.data.list,
            })
        });
    };

    handleTableChange = (page, filter, sort) => {
        let data = this.formRef.current.getFieldsValue();
        if (page.current != this.state.pagination.current) {
            this.state.pagination.current = page.current;
            // this.state.queryParam.page = page.current
            data.page = page.current;
        }
        this.queryData(data)
    };

    // 表单提交
    submit = (v) => {
        this.queryData(v)
    }

    // 重置查询
    reload = () => {
        this.formRef.current.submit();
    }

    // 重置表单
    onReset = (v) => {
        this.formModalRef.current.setFieldsValue(this.queryParam);
    }

    // 显示弹窗
    showModal = (e) => {
        let id = e.currentTarget.dataset.id || 0;
        let data = {
            openModal: true
        };
        if (id > 0) {
            RequestComponent('wechat/officialAdd', {
                method: "GET",
                data: {
                    wechat_id: id
                }
            }).then(r => {
                if (r.data.data.data.WechatId > 0) {
                    if (r.data.data.data.Type == 1) {
                        this.state.wx.official = true;
                        this.state.wx.mini = false;
                        this.state.wx.pay = false;
                    } else if (r.data.data.data.Type == 2) {
                        this.state.wx.official = false;
                        this.state.wx.mini = true;
                        this.state.wx.pay = false;
                    } else if (r.data.data.data.Type == 3) {
                        this.state.wx.official = false;
                        this.state.wx.mini = false;
                        this.state.wx.pay = true;
                    }
                    let data = {
                        openModal: true,
                        imageUrl: ossUrl + r.data.data.data.ImageKey
                    };
                    this.formModalRef.current.setFieldsValue({
                        status: r.data.data.data.Status,
                        type: r.data.data.data.Type,
                        name: r.data.data.data.Name,
                        appid: r.data.data.data.Appid,
                        appsecret: r.data.data.data.Appsecret,
                        mchid: r.data.data.data.Mchid,
                        paykey: r.data.data.data.Paykey,
                        status: r.data.data.data.Status,
                        subscribe_content: r.data.data.data.SubscribeContent,
                        token: r.data.data.data.Token,
                        id: r.data.data.data.WechatId,
                        channel_id: r.data.data.data.ChannelId,
                    });
                    this.setState(data);
                }
            });
        } else {
            this.setState(data);
        }
    }

    // 关闭弹窗
    closeModal = () => {
        this.formModalRef.current.setFieldsValue(this.initialValues);
        this.setState({
            openModal: false,
            imageUrl: ''
        });
    }

    // 表单提交
    onFinish = (v) => {
        if (v.name != '') {
            RequestComponent('wechat/officialAdd', {
                data: v
            }).then(r => {
                if (r.data.code == 0) {
                    let logText = '添加微信号：' + v.Name;
                    if (v.CategoryId != undefined) {
                        logText = '编辑微信号：' + v.Name;
                    }
                    RequestComponent('log/add', {
                        data: {
                            content: logText
                        }
                    });
                    message.success({
                        content: '提交成功',
                        style: {
                            marginTop: '10vh',
                        }
                    });
                    this.closeModal();
                    this.reload();
                } else {
                    message.error({
                        content: r.data.msg,
                        style: {
                            marginTop: '10vh',
                        },
                    });
                }
            });
        }
    }

    /**
     * 删除
     * @param {*} v
     */
    onDel = (v) => {
        if (v.CategoryId > 0) {
            RequestComponent('operate/categoryDelete', {
                data: {
                    id: v.CategoryId
                }
            }).then(r => {
                if (r.data.code == 0) {
                    RequestComponent('log/add', {
                        data: {
                            content: '删除分类：' + v.CategoryName
                        }
                    });
                    this.setState({
                        data: this.state.data.filter(item => item.CategoryId != v.CategoryId)
                    });
                } else {
                    message.error({
                        content: r.data.msg,
                        style: {
                            marginTop: '10vh',
                        }
                    });
                }
            });
        }
    };

    onType = (v) => {
        if (v.target.value == 1) {
            this.setState({
                wx: {
                    official: true,
                    mini: false,
                    pay: false
                }
            })
        } else if (v.target.value == 2) {
            this.setState({
                wx: {
                    official: false,
                    mini: true,
                    pay: false
                }
            })
        } else if (v.target.value == 3) {
            this.setState({
                wx: {
                    official: false,
                    mini: false,
                    pay: true
                }
            })
        }
    }

    /**
     * 上传之前
     * @param {*} v 
     */
    beforeUpload = function (file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('只能上传 JPG/PNG 格式文件!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('图片不得大于 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    /**
     * 上传图片
     * @param {*} info 
     */
    onChangeUpload = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
        }
        if (this.state.loading) {
            this.state.loading = false;
            this.getBase64(info.file.originFileObj, imageUrl => {
                this.setState({
                    imageUrl: imageUrl,
                    loading: false,
                });
                this.formModalRef.current.setFieldsValue({
                    imageUrl: imageUrl
                });
            }
            );
        }
        if (info.file.status == undefined) {
            this.formModalRef.current.setFieldsValue({
                file: undefined
            });
        }
        // if (info.file.status === 'done') {
        //     // Get this url from response in real world.
        //     this.getBase64(info.file.originFileObj, imageUrl =>
        //         this.setState({
        //             imageUrl: imageUrl,
        //             loading: false,
        //         }),
        //     );
        // }
    };
    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }


    render() {
        let columns = [
            {
                title: 'ID',
                dataIndex: 'WechatId',
                width: 100,
                sorter: true,
            },
            {
                title: '类型',
                dataIndex: 'Type',
                width: 100,
                render: (v) => {
                    if (v == 1) {
                        return <Tag color="#f50">公众号</Tag>;
                    } else if (v == 2) {
                        return <Tag color="#f50">小程序</Tag>;
                    } else if (v == 3) {
                        return <Tag color="#f50">支付号</Tag>;
                    }
                }
            },
            {
                title: '名称',
                dataIndex: 'Name',
                width: 100,
            },
            {
                title: 'appid',
                dataIndex: 'Appid',
                width: 100,
            },
            {
                title: 'sercet',
                dataIndex: 'Appsecret',
                width: 200,
            },
            {
                title: 'token',
                width: 100,
                render: (v) => {
                    return 'token';
                }
            },
            {
                title: '操作',
                render: (v) => {
                    return (
                        <Row>
                            <Space size={10}>
                                {this.props.nodeKeys["wechat/officialAdd"] &&
                                    <Button type="primary" size="small" data-id={v.WechatId} onClick={this.showModal}>编辑</Button>
                                }
                            </Space>
                        </Row>
                    )
                }
            }
        ];

        let uploadButton = (
            <div>
                {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>上传封面</div>
            </div>
        );

        let html = (
            <Layout.Content>
                <Form name="submit-form" layout="inline" ref={this.formRef} onFinish={this.submit} initialValues={this.queryParam}>
                    <Form.Item label="类型" name="type">
                        <Select style={{width: 120,}}>
                            <Select.Option key={1} value={1}>公众号</Select.Option>
                            <Select.Option key={2} value={2}>小程序</Select.Option>
                            <Select.Option key={3} value={3}>支付号</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item hidden name='page'>
                        <Input />
                    </Form.Item>
                    <Form.Item hidden name='limit'>
                        <Input />
                    </Form.Item>
                    <Form.Item className="search-button-item">
                        <Space size={10}>
                            <Button htmlType="submit" type="primary">查询</Button>
                            <Button htmlType="reset" type="default" onClick={this.onReset}>清空</Button>
                            <Button shape="circle" icon={<ReloadOutlined />} onClick={this.reload}></Button>
                        </Space>
                    </Form.Item>
                </Form>
                <Divider />
                {this.props.nodeKeys['operate/categoryAdd'] && 
                    <Button type="primary" style={{marginBottom: 20}} onClick={this.showModal}>添加</Button>
                }
                <Table rowClassName={getRowClassName} rowKey="AdminId" columns={columns} dataSource={this.state.data} scroll={{ x: 'max-content' }} pagination={false} size="small"></Table>

                <Modal title={this.state.modalTitle}  open={this.state.openModal} onCancel={this.closeModal} width={600} footer={null} forceRender={true} maskClosable={false}>
                    <Form {...formlayoutModal.form} onFinish={this.onFinish} ref={this.formModalRef} initialValues={this.initialValues}>
                        <Form.Item label="状态" name="status" rules={[{ required: true, message: '请选择类型' }]} >
                                <Radio.Group>
                                    <Radio value={0}>关闭</Radio>
                                    <Radio value={1}>开启</Radio>
                                </Radio.Group>
                            </Form.Item>
                        <Form.Item label="类型" name="type" rules={[{ required: true, message: '请选择类型' }]} >
                            <Radio.Group onChange={this.onType}>
                                <Radio value={1}>公众号</Radio>
                                <Radio value={2}>小程序</Radio>
                                <Radio value={3}>支付号</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="名称" name="name" rules={[{ required: true }]} >
                            <Input autoComplete="off"></Input>
                        </Form.Item>
                        <Form.Item label="appid" name="appid"  >
                            <Input autoComplete="off"></Input>
                        </Form.Item>
                        {!this.state.wx.pay &&
                                <Form.Item label="appsecret" name="appsecret"  >
                                    <Input autoComplete="off"></Input>
                                </Form.Item>
                            }
                            {this.state.wx.official || this.state.wx.mini &&
                                <Form.Item label="token" name="token"  >
                                    <Input autoComplete="off"></Input>
                                </Form.Item>
                            }
                            {this.state.wx.pay &&
                                <Form.Item label="商户号" name="mchid"  >
                                    <Input autoComplete="off"></Input>
                                </Form.Item>
                            }
                            {this.state.wx.pay &&
                                <Form.Item label="支付秘钥" name="paykey"  >
                                    <Input autoComplete="off"></Input>
                                </Form.Item>
                            }
                            {this.state.wx.pay &&
                                <Form.Item label="支付证书序列号" name="certificate_no"  >
                                    <Input autoComplete="off"></Input>
                                </Form.Item>
                            }
                            {this.state.wx.pay &&
                                <Form.Item label="支付证书证书密钥" name="private_key"  >
                                    <Input.TextArea autoComplete="off"></Input.TextArea>
                                </Form.Item>
                            }
                            {this.state.wx.pay &&
                                <Form.Item label="支付通知url" name="notify_url"  >
                                    <Input autoComplete="off"></Input>
                                </Form.Item>
                            }
                            {this.state.wx.pay &&
                                <Form.Item label="退款通知url" name="refund_notify_url"  >
                                    <Input autoComplete="off"></Input>
                                </Form.Item>
                            }
                            {this.state.wx.official &&
                                <Form.Item label="公众号关注回复" name="subscribe_content"  >
                                    <Input.TextArea autoComplete="off" rows={20}></Input.TextArea>
                                </Form.Item>
                            }
                            <Form.Item label="头像" name="file" >
                                <Upload className="avatar-uploader" name="file" listType="picture-card" beforeUpload={this.beforeUpload} onChange={this.onChangeUpload} showUploadList={false}>
                                    {this.state.imageUrl ? <img src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                            </Form.Item>
                        <Form.Item hidden name="id">
                            <Input name="id" />
                        </Form.Item>
                        <Form.Item hidden name="imageUrl">
                                <Input name="imageUrl" />
                            </Form.Item>
                        <Form.Item wrapperCol={formlayoutModal.button}>
                            <Button htmlType="submit" type="primary">提交</Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </Layout.Content>
        );
        return (
            html
        );
    }
}