import { Breadcrumb, Form, Switch } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { Children } from 'react';

export default class Setting extends React.Component {
    constructor(props) {
        super(props);
        this.initialValues = this.getInitialValues();
        this.state = {
            
        };
        this.formRef = React.createRef();
    }

    componentDidMount() {
      
    }


    // 表单默认参数初始化
    getInitialValues = () => {
        return {
            many_tabs: this.getManyTabs()
        };
    }

    // 获取多标签模式状态
    getManyTabs = () => {
        let manyTabs = true;
        const res = localStorage.getItem('many_tabs');
        if (res != null && res == 'false') {
            manyTabs = false;
        }
        return manyTabs;
    }

    // 变更多标签状态
    onManyTabs = (v) => {
        localStorage.setItem('many_tabs', v);
    }

    render() {
        const element = (
            <Form ref={this.formRef} initialValues={this.initialValues}>
                <Form.Item label="多标签模式" name="many_tabs">
                    <Switch onChange={this.onManyTabs} />
                </Form.Item>
            </Form>
        );
        return (
            element
        )
    }
}