
import axios from 'axios';
import { apiUrl } from '../../global';


// export class RequestComponent extends React.Component {
//     static post = (option) => {
//         console.log(option);
//     }
// }

function jsonToParmas(json) {
    var params = Object.keys(json).map(function (key) {
        // body...
        return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
    }).join("&");
    return params;
}

export default function RequestComponent(url, option = {}) {
    const apiurl = apiUrl;
    option.method = option.method || 'POST';
    let header = option.header || {};
    let loginStorage = localStorage.getItem("login");
    if (loginStorage) {
        loginStorage = JSON.parse(loginStorage);
        header["token"] = loginStorage.admin.Token;
    }
    // var data = option.data;
    const config = {
        url: apiurl + url,
        method: option.method,
        headers: header
    };
    if (option.responseType != "") {
        config["responseType"] = option.responseType;
    }
    option.data = option.data || {};
    if (option.method == 'POST') {
        config["data"] = jsonToParmas(option.data);
    }
    if (option.method == 'GET') {
        config["params"] = option.data
    }
    return new Promise(function (resolve, reject) {
        axios.request(config).then(r => {
            if (r.data.code == 40002) {
                return window.location.href = "/login";
            }
            if (r.data.code == 40003) {
                return window.location.href = "/login";
            }
            resolve(r)
        }).catch(error => {
            console.log(error)
        }).then(r => {

        });

    });
}

/**
 * 获取URL的参数
 * @param {*} p 
 */
 export function GetQuery(p) {
    const search = window.location.search.replace('?', '').split('&');
    let r = '';
    for (const i in search) {
        search[i] = search[i].split('=');
        if (search[i][0] == p) {
            r = search[i][1];
            break;
        }
    }
    return r;
}