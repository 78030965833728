export function formlayoutAction() {
    return {
        form: {
            labelCol: {
                sm: { span: 7 },
                md: { span: 4 },
                lg: { span: 2 }
            },
            wrapperCol: {
                span: 24,
            },
        },
        button: {
            sm: { offset: 7 },
            md: { offset: 4 },
            lg: { offset: 2 }
        }
    }
}