import React, { Children } from 'react';
import Layout, { Content } from 'antd/lib/layout/layout';
import { Col, Flex, Row } from 'antd';
import styles from './Datas.module.css';
import RequestComponent from '../../compontents/common/RequestComponent';

export default class DashboardFullView extends React.Component {
    constructor(props) {
        super(props);
        // this.login = this.isLogin(); // 登录信息
        this.isTime = true;
        this.state = {
            data: {}
        }
    }
    componentDidMount = () => {
        this.query();
        
    }

    getRandomInt = (r1, r2) => {
        // 计算范围的宽度  
        let range = r2 - r1 + 1;  
        
        // 生成并映射随机数  
        let randomNumber = Math.floor(Math.random() * range) + parseInt(r1);  
        return randomNumber; 
    }

    query = (data) => {
        const that = this;
        RequestComponent('public/config', {
            method: "GET",
            data: data
        }).then(r => {
            if (r.data.data != null) {
                this.setState({
                    data: r.data.data
                });
            }
        })
    }
    // 是否登录
    isLogin() {
        let login = JSON.parse(localStorage.getItem("login"))
        if (!login || Date.parse(new Date()) / 1000 - login.admin.LoginTime > 7 * 86400) {
            return window.location.href = "/login"
        }
        return login
    }


    render() {
        
        const element = (
            <div style={{
                height: '100vh',
                width: '100vw',
                position: 'relative'
            }}>
                <div style={{
                    zIndex: 999,
                    color: 'white',
                    fontSize: '32px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    position: 'absolute',
                    left: '37vw',
                    top: '8.5vh'
                }}>红杨旅游度假区智慧大屏</div>
                <img className={styles['bg']} src='https://kkk.hongyangtour.com/image/common/datasbg2.jpg'></img>
                <img  style={{
                    width: '59vw',
                    height: '70vh',
                    position: 'absolute',
                    zIndex: 999,
                    left: '7vw',
                    top: '19vh',
                    padding: '2%',
                    borderRadius: '4rem',
                }} src='https://kkk.hongyangtour.com/image/common/fullview.jpg'></img>
                {/* 简介 */}
                <div style={{
                    width: '24vw',
                    height: '38vh',
                    position: 'absolute',
                    zIndex: 999,
                    left: '69vw',
                    top: '19vh',
                    padding: '1%',
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    lineHeight: 1.4
                }}>{this.state.data.introduction}</div>
                {/* 建设情况 */}
                <div style={{
                    width: '24vw',
                    height: '29vh',
                    position: 'absolute',
                    zIndex: 999,
                    left: '69vw',
                    top: '60vh',
                    padding: '1%',
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    lineHeight: 1.4
                }}>{this.state.data.development}</div>
            </div>
        );
        return (
            element
        )
    }
}