import React from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Checkbox, Layout, PageHeader, Card, message, Radio, Upload, Tag, Select } from 'antd';
import { formlayout, apiUrl, ossUrl, formlayoutModal } from '../../../global';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css'
import RequestComponent, { GetQuery } from '../../../compontents/common/RequestComponent';


export default class NoticeAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            scenics: [],
            categorys: [],
            selectCategorys: [],
            title: '添加资讯文章',
            loading: false,
            imageUrl: '',
            flag: 1,
            editorState: BraftEditor.createEditorState(null),
        }
        this.formRef = React.createRef();
    }

    componentDidMount = () => {
        let id = this.props.id;
        RequestComponent('operate/noticeAdd', {
            method: "GET",
            data: { id: id }
        }).then(r => {
            let state = {};
            if (r.data.data.data != null) { 
                this.state.title = '编辑资讯文章';
                if (r.data.data.data.cover_image != "") {
                    state['imageUrl'] = ossUrl + r.data.data.data.cover_image
                }
                this.formRef.current.setFieldsValue({
                    id: r.data.data.data.id,
                    flag: String(r.data.data.data.flag),
                    sort: String(r.data.data.data.sort),
                    home: String(r.data.data.data.home),
                    scenic_id: r.data.data.data.scenic_id,
                    category_id: r.data.data.data.category_id,
                    notice_type: r.data.data.data.notice_type,
                    title: r.data.data.data.title,
                    description: r.data.data.data.description,
                    url: r.data.data.data.url,
                    price: r.data.data.data.price,
                    tags: r.data.data.data.tags,
                    lonlat: r.data.data.data.lonlat,
                    content: BraftEditor.createEditorState(r.data.data.data.content)
                });
                if (r.data.data.data.category_id != "") {
                    let scate = r.data.data.data.category_id.split(',')
                    for (let index = 0; index < scate.length; index++) {
                        this.state.selectCategorys.push(parseInt(scate[index]));
                    }
                }
                this.state.flag = r.data.data.data.flag;
            }
            state['scenics'] = r.data.data.scenics;
            if (r.data.data.categorys.length > 0) {
                for (let index = 0; index < r.data.data.categorys.length; index++) {
                    this.state.categorys.push({
                        label: r.data.data.categorys[index].CategoryName,
                        value: r.data.data.categorys[index].CategoryId
                    });
                }
            }
            this.setState(state);
        });
    }

    submit = (v) => {
        if (typeof v.home == 'object') {
            v.home = v.home[0];
        }
        if (v.title != undefined) {
            if (v.content != "") {
                v.content = v.content.toHTML();
            }
            v.flag = this.state.flag;
            RequestComponent('operate/noticeAdd', {
                data: v
            }).then(r => {
                if (r.data.code == 0) {
                    RequestComponent('log/add', {
                        data: {
                            content: this.state.title + ": " + v.title
                        }
                    });
                    message.success({
                        content: '提交成功',
                        style: {
                            marginTop: '10vh',
                        },
                    });
                    this.props.parent.reload();
                    this.props.parent.closeModal();
                }
            })
        }
    }
    // 分类选择
    selectCategoryChange = (v) => {
        this.formRef.current.setFieldsValue({
            category_id: v.join(',')
        });
    }

    // 是否展示
    onchangeCheck = (v) => {
        if (v.length > 0) {
            this.setState({
                flag: 1,
            });
        } else {
            this.setState({
                flag: 0,
            });
        }
    }

    /**
     * 上传富文本的图片
     */
    myUploadFn = (param) => {
        const serverURL = apiUrl + "public/uploadNoticeImage"
        const xhr = new XMLHttpRequest
        const fd = new FormData()
        const successFn = (response) => {
            // 假设服务端直接返回文件上传后的地址
            // 上传成功后调用param.success并传入上传后的文件地址
            param.success({
                url: ossUrl + JSON.parse(xhr.responseText).data,
            })
        }
        const progressFn = (event) => {
            // 上传进度发生变化时调用param.progress
            param.progress(event.loaded / event.total * 100)
        }
        const errorFn = (response) => {
            // 上传发生错误时调用param.error
            param.error({
                msg: 'unable to upload.'
            })
        }
        xhr.upload.addEventListener("progress", progressFn, false)
        xhr.addEventListener("load", successFn, false)
        xhr.addEventListener("error", errorFn, false)
        xhr.addEventListener("abort", errorFn, false)
        fd.append('file', param.file)
        xhr.open('POST', serverURL, true)
        xhr.send(fd)
    }


    /**
     * 上传封面图片到服务端
     * @param {*} info 
     */
    customRequest = info => {
        const formData = new FormData();
        formData.append('file', info.file);
        fetch(apiUrl + 'public/uploadNoticeCoverImage', {
            method: 'POST',
            body: formData,
            })
        .then(response => response.json())
        .then(data => {
            this.setState({
                loading: false,
            });
            // 这里的data就是服务端返回的参数
            this.formRef.current.setFieldsValue({
                imageUrl: data.data
            });
        });
    };

    /**
     * 上传之前
     * @param {*} v 
     */
    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('只能上传 JPG/PNG 格式文件!');
        }
        const isLt2M = file.size / 1024 / 1024 < 8;
        if (!isLt2M) {
            message.error('图片不得大于 8MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    /**
     * 上传图片
     * @param {*} info 
     */
    onChangeUpload = info => { 
        if (info.file.status === 'uploading') {
            this.state.loading = true;
            this.setState({ loading: true });
        }
        if (this.state.loading) {
            this.state.loading = false;
            this.getBase64(info.file.originFileObj, imageUrl => {
                this.setState({
                    imageUrl: imageUrl,
                });
            });
        }
        if (info.file.status == undefined) {
            this.formRef.current.setFieldsValue({
                file: undefined
            });
        }
    };
    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    render() {
        let uploadButton = (
            <div>
                {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );
        let html = (
            <Layout.Content>
                    {this.state.data &&
                        <Form {...formlayoutModal.form} onFinish={this.submit} ref={this.formRef} initialValues={{flag:'1',home:0,'notice_type':1,title:'',content:'',description:'',imageUrl:'',id:0,land_id:'',url:'',category_id:'', tags:'', price:'', lonlat: '', sort: '0'}} >
                            <Form.Item label="是否展示" name="flag">
                                <Checkbox.Group onChange={this.onchangeCheck} >
                                    <Checkbox key={1} value={1}></Checkbox>
                                </Checkbox.Group>
                            </Form.Item>
                            <Form.Item label="是否上首页" name="home">
                                <Checkbox.Group>
                                    <Checkbox key={1} value={1}></Checkbox>
                                </Checkbox.Group>
                            </Form.Item>
                            <Form.Item label="类型" name="notice_type" rules={[{ required: true }]} >
                                <Radio.Group>
                                    <Radio value={1}>文章</Radio>
                                    <Radio value={2}>banner</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label="什么是类型" name="flag">
                                <Tag color="red">文章：普通资讯</Tag>
                                <Tag color="red">banner：普通资讯并且上首页banner</Tag>
                            </Form.Item>
                            <Form.Item label="关联景点id" name="scenic_id"  >
                                {this.state.scenics.length > 0 &&
                                    <Select showSearch>
                                        <Select.Option key={0} value={0}>无-选择关联景点后此文章默认导向的是景点详情</Select.Option>
                                        {this.state.scenics.map((i, e) => (
                                            <Select.Option key={i.id} value={i.id}>{i.name}</Select.Option>
                                        ))}
                                    </Select>
                                }
                            </Form.Item>
                            <Form.Item label="什么是关联景点" name="flag">
                                <Tag color="red">关联景点后点击文章内容会直接跳转到景点详情</Tag>
                            </Form.Item>
                            <Form.Item label="文章分类">
                                {this.state.categorys.length > 0 &&
                                    <Checkbox.Group  options={this.state.categorys} defaultValue={this.state.selectCategorys} onChange={this.selectCategoryChange}>

                                    </Checkbox.Group>
                                }
                            </Form.Item>
                            <Form.Item label="标题" name="title" rules={[{ required: true }]} >
                                <Input autoComplete="off"></Input>
                            </Form.Item>
                            <Form.Item label="描述" name="description"  >
                                <Input autoComplete="off"></Input>
                            </Form.Item>
                            <Form.Item label="排序(升序)" name="sort"  >
                                <Input autoComplete="off"></Input>
                            </Form.Item>
                            <Form.Item label="标签,号分割"  name="tags">
                                <Input autoComplete="off"></Input>
                            </Form.Item>
                            <Form.Item label="经纬度" name="lonlat">
                                <Input autoComplete="off" placeholder='维度在前,精度在后(注意使用英文,号分割)'></Input>
                             </Form.Item>
                             <Form.Item label="什么是经纬度" name="flag">
                                <Tag color="red">智慧导览文章传人此字段，可以直接跳转到地图</Tag>
                                <a href='https://lbs.qq.com/getPoint/' target='_blank'>点击获取坐标跳转</a>
                            </Form.Item>
                            {/* <Form.Item label="价格"  name="price">
                                <Input autoComplete="off"></Input>
                            </Form.Item> */}
                            <Form.Item label="公众号链接" name="url"  >
                                <Input autoComplete="off"></Input>
                            </Form.Item>
                            <Form.Item label="什么是公众号链接" name="flag">
                                <Tag color="red">公众号关联小程序后可在此输入公众号素材的链接，点击后会跳转到公众号链接</Tag>
                            </Form.Item>
                            <Form.Item label="封面" name="file" >
                                <Upload className="avatar-uploader" listType="picture-card" beforeUpload={this.beforeUpload} customRequest={this.customRequest} onChange={this.onChangeUpload}  showUploadList={false}>
                                    {this.state.imageUrl ? <img src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                            </Form.Item>
                            <Form.Item label="内容" name="content" >
                                <BraftEditor placeholder="请输入内容" className="my-editor" value={this.state.editorState} media={{ uploadFn: this.myUploadFn }} />
                            </Form.Item>
                            <Form.Item hidden name="imageUrl" >
                                <Input  />
                            </Form.Item>
                            <Form.Item hidden name="category_id">
                                <Input />
                            </Form.Item>
                            <Form.Item hidden name="id">
                                <Input />
                            </Form.Item>
                            <Form.Item wrapperCol={formlayoutModal.button}>
                                <Button htmlType="submit" type="primary" disabled={this.state.loading ? true : false}>{this.state.loading ? '上传中' : '提交'}</Button>
                            </Form.Item>
                        </Form>
                    }
            </Layout.Content>
        );
        return (
            html
        )
    }
}