import React from 'react';
import { Button, Form, Input, Row, Table, Layout, Col, Space, Divider, Select, Popconfirm, Card, Modal, message } from 'antd';
import RequestComponent from '../../../compontents/common/RequestComponent';
import { timeToDateTime } from '../../../compontents/common/DateComponent'
import { formlayout, formlayoutModal, getRowClassName, searchFormCol } from "../../../global";
import {ReloadOutlined }  from '@ant-design/icons';

export default class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.formModalRef = React.createRef();
        // 查询参数
        this.queryParam = {
            page: 1,
            mobile: '',
            limit: 20,
            sort: ''
        };
        this.state = {
            data: [],
            group: [],
            openModal: false,
            modalTitle: '添加',
            validate: {
                admin_user: {}
            },
            pagination: {
                current: 1,
                position: ["bottomLeft"],
                hideOnSinglePage: true,
                total: 0,
                showQuickJumper: true,
                pageSize: this.queryParam.limit,
                showSizeChanger: false,
                showTotal: (total) => {return '共' + total + '条'}
            }
        }
    }

    componentDidMount = () => {
        this.queryData();
    };

    queryData = (data) => {
        if (data == undefined) {
            data = this.queryParam;
        }
        RequestComponent("user/list", {
            method: "GET",
            data: data
        }).then(r => {
            if (r.data.code == 0) {
                this.state.pagination.total = r.data.data.count;
                this.setState({
                    data: r.data.data.list,
                    pagination: this.state.pagination
                });
            }
        });
    };

    // 表格变更
    handleTableChange = (page, filter, sort) => {
        let data = this.formRef.current.getFieldsValue();
        if (page.current != this.state.pagination.current) {
            this.state.pagination.current = page.current;
            data.page = page.current;
        }
        if (sort.field != undefined && sort.order != undefined) {
            data.sort = sort.field + '-' + sort.order;
        }
        this.queryData(data)
    };

    // 表单提交
    submit = (v) => {
        this.queryData(v)
    }

    // 重置查询
    reload = () => {
        this.formRef.current.submit();
    }

    // 重置表单
    onReset = (v) => {
        this.formRef.current.setFieldsValue(this.queryParam);
    }

    // 表单提交
    onFinish = (v) => {
        if (v.admin_user != '' && v.admin_pwd != '') {
            RequestComponent('system/adminAdd', {
                data: v
            }).then(r => {
                if (r.data.code == 0) {
                    let logText = '添加管理员：' + v.admin_user;
                    if (v.id != undefined) {
                        logText = '编辑管理员：' + v.admin_pwd;
                    }
                    RequestComponent('log/add', {
                        data: {
                            content: logText
                        }
                    });
                    message.success({
                        content: '提交成功',
                        style: {
                            marginTop: '10vh',
                        },
                    });
                    this.closeModal();
                    this.reload();
                } else if (r.data.code > 0) {
                    this.state.validate.admin_user = {
                        validateStatus: 'error',
                        help: r.data.msg
                    };
                    this.setState({
                        validate: this.state.validate
                    });
                }
            });
        }
    }


    render() {
        let columns = [
            {
                title: 'ID',
                dataIndex: 'user_id',
                width: 100
            },
            {
                title: 'openid',
                dataIndex: 'openid',
                width: 150,
                render: (v) => {
                    return (
                        v
                    )
                }
            },
            {
                title: '手机',
                dataIndex: 'mobile',
                width: 100
            },
            {
                title: '创建时间',
                dataIndex: 'create_time',
                width: 200,
                sorter: true,
                render: (v) => {
                    return timeToDateTime(v)
                }
            },
            // {
            //     title: '操作',
            //     render: (v,r) => {
            //         return (
            //             <Row>
            //                 <Space size={10}>
            //                     {this.props.nodeKeys["user/userAdd"] &&
            //                         <Button type="primary" size="small" data-id={v.user_id} onClick={this.showModal}>编辑</Button>
            //                     }
            //                 </Space>
            //             </Row>
            //         )
            //     }
            // }
        ];
        let html = (
            <Layout.Content>
                <Form name="submit-form" layout="inline" ref={this.formRef} onFinish={this.submit} initialValues={this.queryParam}>
                    <Form.Item label="手机号" name="mobile">
                        <Input placeholder="手机号" />
                    </Form.Item>
                    <Form.Item hidden name='page'>
                        <Input />
                    </Form.Item>
                    <Form.Item hidden name='limit'>
                        <Input />
                    </Form.Item>
                    <Form.Item hidden name='sort'>
                        <Input />
                    </Form.Item>
                    <Form.Item className="search-button-item">
                        <Space size={10}>
                            <Button htmlType="submit" type="primary">查询</Button>
                            <Button htmlType="reset" type="default" onClick={this.onReset}>清空</Button>
                            <Button shape="circle" icon={<ReloadOutlined />} onClick={this.reload}></Button>
                        </Space>
                    </Form.Item>
                </Form>
                <Divider />
                <Table rowClassName={getRowClassName} rowKey="user_id" columns={columns} dataSource={this.state.data} scroll={{ x: 'max-content' }} pagination={this.state.pagination} size="small" onChange={this.handleTableChange}></Table>

            </Layout.Content>
        );
        return (
            html
        );
    }
}