import React from 'react';
import { Button, Form, Input, Row, Table, Layout, Col, Space, Divider, Select, Popconfirm, Card, Modal, message, Upload } from 'antd';
import RequestComponent from '../../../compontents/common/RequestComponent';
import { timeToDateTime } from '../../../compontents/common/DateComponent'
import { apiUrl, formlayout, formlayoutModal, getRowClassName, ossUrl, searchFormCol } from "../../../global";
import {LoadingOutlined, PlusOutlined, ReloadOutlined }  from '@ant-design/icons';

export default class ScenicList extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.formModalRef = React.createRef();
        this.initialValues = this.getInitialValues();
        // 查询参数
        this.queryParam = {
            page: 1,
            limit: 20,
        };
        this.state = {
            data: [],
            openModal: false,
            modalTitle: '添加',
            coverImageUrl: '',
            loading: false,
            loading2: false,
            fileList: [],
            imagesKey: [],
            pagination: {
                current: 1,
                position: ["bottomLeft"],
                hideOnSinglePage: true,
                total: 0,
                showQuickJumper: true,
                pageSize: this.queryParam.limit,
                showSizeChanger: false,
                showTotal: (total) => {return '共' + total + '条'}
            }
        }
    }

    componentDidMount = () => {
        this.queryData();
    };

    getInitialValues = () => {
        return {
            name: '',
            description: '',
            sort: '',
            open_time: '',
            phone: '',
            tags: '',
            map_link: '',
            images_key_url: '',
            cover_image_url: '',
            lonlat: '',
            traffic: '',
            tip: '',
            id: 0
        };
    }

    queryData = (data) => {
        if (data == undefined) {
            data = this.queryParam;
        }
        RequestComponent('scenic/scenicList', {
            method: "GET",
            data: data,
        }).then(r => {
            if (r.data.code == 0) {
                this.state.pagination.total = r.data.data.count;
                this.setState({
                    data: r.data.data.list,
                    pagination: this.state.pagination
                });
            }
        });
    };

    // 表格变更
    handleTableChange = (page, filter, sort) => {
        let data = this.formRef.current.getFieldsValue();
        if (page.current != this.state.pagination.current) {
            this.state.pagination.current = page.current;
            data.page = page.current;
        }
        if (sort.field != undefined && sort.order != undefined) {
            data.sort = sort.field + '-' + sort.order;
        }
        this.queryData(data)
    };

    // 表单提交
    submit = (v) => {
        this.queryData(v)
    }

    // 重置查询
    reload = () => {
        if (this.state.pagination.current > 1) {
            this.formRef.current.setFieldsValue({
                page: this.state.pagination.current
            });
        }
        this.formRef.current.submit();
    }

    // 重置表单
    onReset = (v) => {
        this.formRef.current.setFieldsValue(this.queryParam);
    }

    // 显示弹窗
    showModal = (e) => {
        let id = e.currentTarget.dataset.id || 0;
        let data = {
            openModal: true
        };
        this.state.fileList = [];
        this.state.imagesKey = [];
        RequestComponent('scenic/scenicAdd', {
            method: "GET",
            data: {
                id: id
            }
        }).then(r => {
            data['group'] = r.data.data.group;
            if (r.data.data.data != null) {
                data['modalTitle'] = '编辑';
                let filed = r.data.data.data;
                this.formModalRef.current.setFieldsValue({
                    id: filed.id,
                    name: filed.name,
                    description: filed.description,
                    sort: filed.sort,
                    open_time: filed.open_time,
                    phone: filed.phone,
                    tags: filed.tags,
                    map_link: filed.map_link,
                    lonlat: filed.lonlat,
                    traffic: filed.traffic,
                    tip	: filed.tip,
                });
                let imgsJson = {coverImageUrl:"",imagesKeyUrl:""};
                if (filed.cover_image != "") {
                    imgsJson.coverImageUrl = ossUrl + filed.cover_image;
                }
                if (filed.images_key != "") {
                    let imgs = filed.images_key.split(",")

                    for (let item in imgs) {
                        this.state.fileList.push({
                            name: imgs[item],
                            status: "done",
                            url: ossUrl + imgs[item]
                        });
                        this.state.imagesKey.push({
                            name: imgs[item],
                            status: "done",
                            url: imgs[item]
                        });
                    }
                }
                this.setState(imgsJson);
            }
            this.setState(data);
        });
    }

    // 关闭弹窗
    closeModal = () => {
        this.formModalRef.current.setFieldsValue(this.initialValues);
        this.setState({
            openModal: false,
            fileList: [],
            coverImageUrl: ''
        });
    }

    /**
     * 删除
     * @param {*} v 
     */
    onDel = (v) => {
        RequestComponent('scenic/scenicDelete', {
            data: {
                id: v.id,
                isDel: 1
            }
        }).then(r => {
            if (r.data.code == 0) {
                RequestComponent('log/add', {
                    data: {
                        content: '删除景点：' + v.title
                    }
                });
                this.setState({
                    data: this.state.data.filter(item => item.id != v.id)
                });
            } else {
                message.error({
                    content: r.data.msg,
                    style: {
                        marginTop: '10vh',
                    }
                });
            }
        });
    }

    // 表单提交
    onFinish = (v) => {
        if (v.name != undefined) {
            let imgurls = "";
            for (let item in this.state.imagesKey) {
                if (this.state.imagesKey[item]["url"].indexOf('?')) {
                    imgurls += this.state.imagesKey[item]["url"].split('?')[0] + ","
                } else {
                    imgurls += this.state.imagesKey[item]["url"] + ","
                }
            }
            v.images_key_url = imgurls;
            RequestComponent('scenic/scenicAdd', {
                data: v
            }).then(r => {
                if (r.data.code == 0) {
                    let logText = '添加景点：' + v.name;
                    if (v.CategoryId != undefined) {
                        logText = '编辑景点：' + v.name;
                    }
                    RequestComponent('log/add', {
                        data: {
                            content: logText
                        }
                    });
                    message.success({
                        content: '提交成功',
                        style: {
                            marginTop: '10vh',
                        }
                    });
                    this.closeModal();
                    this.reload();
                }
            })
        }
    }

    /**
     * 上传之前
     * @param {*} v 
     */
    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('只能上传 JPG/PNG 格式文件!');
        }
        const isLt2M = file.size / 1024 / 1024 < 8;
        if (!isLt2M) {
            message.error('图片不得大于 8MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    /**
     * 上传图片封面
     * @param {*} info 
     */
    customRequest = info => {
        const formData = new FormData();
        formData.append('file', info.file);
        fetch(apiUrl + 'public/uploadScenicImage', {
            method: 'POST',
            body: formData,
          })
        .then(response => response.json())
        .then(data => {
            this.setState({
                loading: false,
            });
            // 这里的data就是服务端返回的参数
            this.formModalRef.current.setFieldsValue({
                cover_image_url: data.data
            });
        });
    };
    /**
     * 上传图片
     * @param {*} info 
     */
    onChangeUpload = info => {
        if (info.file.status === 'uploading') {
            this.state.loading = true;
            this.setState({ loading: true });
        }
        if (this.state.loading) {
            this.state.loading = false;
            this.getBase64(info.file.originFileObj, imageUrl => {
                this.setState({
                    coverImageUrl: imageUrl,
                    loading: false,
                });
            });
        }
        if (info.file.status == undefined) {
            this.formModalRef.current.setFieldsValue({
                file: undefined
            });
        }
    };

    /**
     * 上传之前
     * @param {*} v 
     */
    beforeUpload2 = file =>  {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif';
        if (!isJpgOrPng) {
            message.error('图片格式不正确!');
        }
        const isLt2M = file.size / 1024  < 80480;
        if (!isLt2M) {
            message.error('图片不得大于 ' + 8 + 'MB! 会导致用户加载慢，影响用户体验');
        }
        return isJpgOrPng && isLt2M;
    }
    /**
     * 上传图片
     * @param {*} info 
     */
    customRequest2 = info => {
        const formData = new FormData();
        formData.append('file', info.file);
        fetch(apiUrl + 'public/uploadScenicImage', {
            method: 'POST',
            body: formData,
          })
        .then(response => response.json())
        .then(data => {
            this.setState({
                loading2: false,
            });
            // 这里的data就是服务端返回的参数
            this.state.imagesKey.push({
                name: info.file.name,
                status : "done",
                url : data.data
            })
        });
    };

    /**
     * 上传图片
     * @param {*} info 
     */
    onChangeUpload2 = info => {
        if (info.file.status === 'uploading') {
            this.state.loading2 = true;
            this.setState({
                loading2: this.state.loading2
            });
        }
        if (info.file.status == "removed") {
            for (let item in this.state.fileList) {
                if (this.state.fileList[item].name == info.file.name) {
                    this.state.fileList.splice(item,1);
                }
                if (this.state.imagesKey[item].name == info.file.name) {
                    this.state.imagesKey.splice(item,1);
                }
            }
            this.setState({
                fileList: this.state.fileList
            });
        }
        if (this.state.loading2) {
            this.state.loading2 = false;
            this.getBase64(info.file.originFileObj, imageUrl => {
                this.state.fileList.push({
                    name: info.file.name,
                    status : "done",
                    url : imageUrl
                })
                this.setState({
                    fileList: this.state.fileList
                });
            });
        }
    };

    
    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }


    render() {
        let columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                align: 'center',
            },
            {
                title: '创建时间',
                dataIndex: 'create_time',
                align: 'center',
                render: (v) => {
                    return timeToDateTime(v);
                }
            },
            {
                title: '名称',
                dataIndex: 'name',
                align: 'center'
            },
            {
                title: '开放时间',
                dataIndex: 'open_time',
                align: 'center',
            },
            {
                title: '电话',
                dataIndex: 'phone',
                align: 'center',
            },
            {
                title: '标签',
                dataIndex: 'tags',
                align: 'center',
            },
            {
                title: '操作',
                render: (r) => {
                    return (
                        <Row>
                            <Space size={10}>
                                {this.props.nodeKeys["scenic/scenicAdd"] &&
                                    <Button type="primary" size="small" data-id={r.id} onClick={this.showModal}>编辑</Button>
                                }
                                {this.props.nodeKeys["scenic/scenicDelete"] &&
                                    <Popconfirm title="确定删除?" onConfirm={() => this.onDel(r)}>
                                        <Button type="primary" danger size="small" >删除</Button>
                                    </Popconfirm>
                                }
                            </Space>
                        </Row>
                    )
                }
            }
        ];

        let uploadButton = (
            <div>
                {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>上传封面</div>
            </div>
        );

        let uploadButton2 = (
            <div>
                {this.state.loading2 ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>上传图集</div>
            </div>
        );


        let html = (
            <Layout.Content>
                <Form name="submit-form" layout="inline" ref={this.formRef} onFinish={this.submit} initialValues={this.queryParam}>
                    <Form.Item hidden name='page'>
                        <Input />
                    </Form.Item>
                    <Form.Item hidden name='limit'>
                        <Input />
                    </Form.Item>
                </Form>
                    <Space size={10}>
                        {this.props.nodeKeys['scenic/scenicAdd'] && 
                            <Button type="primary" style={{marginBottom: 20}} onClick={this.showModal}>添加</Button>
                        }
                        <Button shape="circle" icon={<ReloadOutlined />} style={{marginBottom: 20}} onClick={this.reload}></Button>
                    </Space>
                <Table rowClassName={getRowClassName} rowKey="id" columns={columns} dataSource={this.state.data} scroll={{ x: 'max-content' }} pagination={this.state.pagination} size="small" onChange={this.handleTableChange}></Table>

                <Modal title={this.state.modalTitle}  open={this.state.openModal} onCancel={this.closeModal} width={800} footer={null} forceRender={true} maskClosable={false}>
                    <Form {...formlayoutModal.form} onFinish={this.onFinish} ref={this.formModalRef} initialValues={this.initialValues}>
                        <Form.Item label="名称" name="name" rules={[{ required: true }]} >
                            <Input autoComplete="off"></Input>
                        </Form.Item>
                        <Form.Item label="描述" name="description" rules={[{ required: true }]} >
                            <Input.TextArea autoComplete="off" rows={5}></Input.TextArea>
                        </Form.Item>
                        <Form.Item label="交通" name="traffic"  >
                            <Input.TextArea autoComplete="off" rows={5}></Input.TextArea>
                        </Form.Item>
                        <Form.Item label="详情" name="tip"  >
                            <Input.TextArea autoComplete="off" rows={5}></Input.TextArea>
                        </Form.Item>
                        <Form.Item label="排序"  name="sort">
                            <Input autoComplete="off"></Input>
                        </Form.Item>
                        <Form.Item label="开放时间"  name="open_time">
                            <Input.TextArea autoComplete="off"></Input.TextArea>
                        </Form.Item>
                        <Form.Item label="电话"  name="phone">
                            <Input autoComplete="off"></Input>
                        </Form.Item>
                        <Form.Item label="标签,号分割"  name="tags">
                            <Input autoComplete="off"></Input>
                        </Form.Item>
                        <Form.Item label="经纬度"  name="lonlat">
                            <Input autoComplete="off"></Input>
                        </Form.Item>
                        <Form.Item hidden name="id">
                            <Input name="id" />
                        </Form.Item>
                        <Form.Item label="坐标拾取" >
                            <a href='https://lbs.qq.com/getPoint/' target='_blank'>点击跳转</a>
                        </Form.Item>
                        <Form.Item label="封面" name="file" >
                            <Upload className="avatar-uploader" listType="picture-card" beforeUpload={this.beforeUpload} onChange={this.onChangeUpload} customRequest={this.customRequest} showUploadList={false}>
                                {this.state.coverImageUrl ? <img src={this.state.coverImageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Form.Item>
                        <Form.Item label="图片集合" name="files" >
                            <Upload   className="avatar-uploader"  listType="picture-card" fileList={this.state.fileList} multiple={true} beforeUpload={this.beforeUpload2} customRequest={this.customRequest2} onChange={this.onChangeUpload2} showUploadList={true} maxCount={12}> 
                                {uploadButton2}
                            </Upload>
                        </Form.Item>
                        <Form.Item hidden name="id">
                            <Input name="id" />
                        </Form.Item>
                        <Form.Item hidden name="cover_image_url">
                            <Input  />
                        </Form.Item>
                        <Form.Item hidden name="images_key_url">
                            <Input  />
                        </Form.Item>
                        <Form.Item wrapperCol={formlayoutModal.button}>
                            <Button htmlType="submit" type="primary"  disabled={this.state.loading || this.state.loading2 ? true : false}>{this.state.loading || this.state.loading2 ? '上传中' : '提交'}</Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </Layout.Content>
        );
        return (
            html
        );
    }
}