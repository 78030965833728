import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import { Error404 } from "./pages/error/Error";
import LogList from "./pages/log/LogList";
import DashboardDatas from "./pages/dashboard/DashboardDatas";
import DashboardFullView from "./pages/dashboard/DashboardFullView";

function router() {
    return (
        <Router>
            <Switch>
                <Route exact path="/login" component={Login}></Route>
                <Route exact path={"/"} component={Dashboard}></Route>
                <Route exact path={"/setting"} component={Dashboard}></Route>

                {/* 仪表盘 */}
                <Route exact path={"/dashboard/datas"} component={DashboardDatas}></Route>
                <Route exact path={"/dashboard/fullview"} component={DashboardFullView}></Route>

                {/* 用户 */}
                <Route exact path={"/user/list"} component={Dashboard}></Route>

                {/* 景区 */}
                <Route exact path={"/scenic/scenicList"} component={Dashboard}></Route>
                <Route exact path={"/scenic/ticket"} component={Dashboard}></Route>

                {/* 运营 */}
                <Route exact path={"/operate/category"} component={Dashboard}></Route>
                <Route exact path={"/operate/config"} component={Dashboard}></Route>
                <Route exact path={"/wechat/official"} component={Dashboard}></Route>
                <Route exact path={"/operate/notice"} component={Dashboard}></Route>

                {/* 系统 */}
                <Route exact path={"/system/site"} component={Dashboard}></Route>
                <Route exact path={"/system/admin"} component={Dashboard}></Route>
                <Route exact path={"/system/group"} component={Dashboard}></Route>
                <Route exact path={"/system/node"} component={Dashboard}></Route>

                {/* 日志 */}
                <Route exact path={"/log/list"} component={Dashboard}></Route>

                <Route exact path="/error/404" component={Error404}></Route>
                <Redirect to="/error/404" />
            </Switch>
        </Router>
    )
}

export default router;