import React from 'react';
import { Button, Form, Input, Row, Table, Layout, Col, Space, Divider, Select, Popconfirm, Card, Modal, message } from 'antd';
import RequestComponent from '../../../compontents/common/RequestComponent';
import { timeToDateTime } from '../../../compontents/common/DateComponent'
import { formlayout, formlayoutModal, getRowClassName, searchFormCol } from "../../../global";
import {ReloadOutlined }  from '@ant-design/icons';

export default class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.formModalRef = React.createRef();
        this.initialValues = this.getInitialValues();
        // 查询参数
        this.queryParam = {
            page: 1,
            admin_user: "",
            limit: 20
        };
        this.state = {
            data: [],
            group: [],
            openModal: false,
            modalTitle: '添加',
            validate: {
                admin_user: {}
            },
            pagination: {
                current: 1,
                position: ["bottomLeft"],
                hideOnSinglePage: true,
                total: 0,
                showQuickJumper: true,
                pageSize: 20,
                showSizeChanger: false,
            }
        }
    }

    componentDidMount = () => {
        this.queryData();
    };

    getInitialValues = () => {
        return {
            admin_user: '',
            admin_pwd: '',
            group_id: '',
            id: 0
        };
    }

    queryData = (data) => {
        if (data == undefined) {
            data = this.queryParam;
        }
        RequestComponent("system/admin", {
            method: "GET",
            data: data
        }).then(r => {
            if (r.data.code == 0) {
                this.state.pagination.total = r.data.data.count;
                this.setState({
                    data: r.data.data.list,
                    pagination: this.state.pagination
                });
            }
        });
    };

    handleTableChange = (page, filter, sort) => {
        if (page.current != this.state.pagination.current) {
            this.state.pagination.current = page.current;
            // this.state.queryParam.page = page.current
            let data = this.formRef.current.getFieldsValue();
            data.page = page.current;
            this.queryData(data)
        }
    };

    // 表单提交
    submit = (v) => {
        this.queryData(v)
    }

    // 重置查询
    reload = () => {
        this.formRef.current.submit();
    }

    // 重置表单
    onReset = (v) => {
        this.formRef.current.setFieldsValue(this.queryParam);
    }

    // 显示弹窗
    showModal = (e) => {
        let id = e.currentTarget.dataset.id || 0;
        let data = {
            openModal: true
        };
        RequestComponent('system/adminAdd', {
            method: "GET",
            data: {
                id: id
            }
        }).then(r => {
            data['group'] = r.data.data.group;
            if (r.data.data.data != null) {
                data['modalTitle'] = '编辑';
                this.formModalRef.current.setFieldsValue({
                    id: r.data.data.data.AdminId,
                    admin_user: r.data.data.data.AdminUser,
                    admin_pwd: r.data.data.data.AdminPwd,
                    group_id: r.data.data.data.GroupId,
                });
            }
            this.setState(data);
        });
    }

    // 关闭弹窗
    closeModal = () => {
        this.formModalRef.current.setFieldsValue(this.initialValues);
        this.setState({
            openModal: false
        });
    }

    // 表单提交
    onFinish = (v) => {
        if (v.admin_user != '' && v.admin_pwd != '') {
            RequestComponent('system/adminAdd', {
                data: v
            }).then(r => {
                if (r.data.code == 0) {
                    let logText = '添加管理员：' + v.admin_user;
                    if (v.id != undefined) {
                        logText = '编辑管理员：' + v.admin_pwd;
                    }
                    RequestComponent('log/add', {
                        data: {
                            content: logText
                        }
                    });
                    message.success({
                        content: '提交成功',
                        style: {
                            marginTop: '10vh',
                        },
                    });
                    this.closeModal();
                    this.reload();
                } else if (r.data.code > 0) {
                    this.state.validate.admin_user = {
                        validateStatus: 'error',
                        help: r.data.msg
                    };
                    this.setState({
                        validate: this.state.validate
                    });
                }
            });
        }
    }

    /**
     * 删除
     * @param {*} v
     */
    onDel = (v) => {
        if (v.AdminId > 0) {
            RequestComponent('system/adminDelete', {
                data: {
                    id: v.AdminId
                }
            }).then(r => {
                if (r.data.code == 0) {
                    RequestComponent('log/add', {
                        data: {
                            content: '删除管理员：' + v.AdminUser
                        }
                    });
                    this.setState({
                        data: this.state.data.filter(item => item.AdminId != v.AdminId)
                    });
                }
            });
        }
    };


    render() {
        let columns = [
            {
                title: 'ID',
                dataIndex: 'AdminId',
                width: 100
            },
            {
                title: '账号',
                dataIndex: 'AdminUser',
            },
            {
                title: '管理组',
                dataIndex: 'GroupName',
            },
            {
                title: '创建时间',
                dataIndex: 'CreateTime',
                sorter: true,
                render: (v) => {
                    return timeToDateTime(v);
                }
            },
            {
                title: '登录时间',
                dataIndex: 'LoginTime',
                sorter: true,
                render: (v) => {
                    if (v > 0) {
                        return timeToDateTime(v);
                    } else {
                        return '————';
                    }
                }
            },
            {
                title: '操作',
                render: (v) => {
                    return (
                        <Row>
                            <Button type="primary" size="small" data-id={v.AdminId} onClick={this.showModal}>编辑</Button>
                            &nbsp;
                            {v.AdminId > 1 &&
                                <Popconfirm title="确定删除?" onConfirm={() => this.onDel(v)}>
                                    <Button type="primary" danger size="small">删除</Button>
                                </Popconfirm>
                            }
                        </Row>
                    );
                }
            }
        ];
        let html = (
            <Layout.Content>
                <Form name="submit-form" layout="inline" ref={this.formRef} onFinish={this.submit} initialValues={this.queryParam}>
                    <Form.Item label="账号名称" name="admin_user">
                        <Input placeholder="账号名称" />
                    </Form.Item>
                    <Form.Item hidden name='page'>
                        <Input />
                    </Form.Item>
                    <Form.Item hidden name='limit'>
                        <Input />
                    </Form.Item>
                    <Form.Item className="search-button-item">
                        <Space size={10}>
                            <Button htmlType="submit" type="primary">查询</Button>
                            <Button htmlType="reset" type="default" onClick={this.onReset}>清空</Button>
                            <Button shape="circle" icon={<ReloadOutlined />} onClick={this.reload}></Button>
                        </Space>
                    </Form.Item>
                </Form>
                <Divider />
                <Button type="primary" style={{marginBottom: 20}} onClick={this.showModal}>
                    {this.props.nodeKeys['system/adminAdd'] && 
                        "添加"
                    }
                </Button>
                <Table rowClassName={getRowClassName} rowKey="AdminId" columns={columns} dataSource={this.state.data} scroll={{ x: 'max-content' }} pagination={false} size="small"></Table>

                <Modal title={this.state.modalTitle}  open={this.state.openModal} onCancel={this.closeModal} width={600} footer={null} forceRender={true} maskClosable={false}>
                    <Form {...formlayoutModal.form} onFinish={this.onFinish} ref={this.formModalRef} initialValues={this.initialValues}>
                        <Form.Item label="账号" name="admin_user" rules={[{ required: true }]} {...this.state.validate.admin_user}>
                            <Input autoComplete="off"></Input>
                        </Form.Item>
                        <Form.Item label="密码" name="admin_pwd" rules={[{ required: true }]}>
                            <Input autoComplete="off"></Input>
                        </Form.Item>
                        <Form.Item label="管理组" name="group_id" rules={[{ required: true }]}>
                            {this.state.group.length > 0 &&
                                <Select >
                                    {this.state.group.map((i, e) => (
                                        <Select.Option key={i.GroupId} value={i.GroupId}>{i.GroupName}</Select.Option>
                                    ))}
                                </Select>
                            }
                        </Form.Item>
                        <Form.Item hidden name="id">
                            <Input name="id" />
                        </Form.Item>
                        <Form.Item wrapperCol={formlayoutModal.button}>
                            <Button htmlType="submit" type="primary">提交</Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </Layout.Content>
        );
        return (
            html
        );
    }
}