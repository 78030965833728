export function timeToDateTime(time) {
    let date = new Date(time * 1000)
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2);
}

export function timeToDate(time) {
    let date = new Date(time * 1000)
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
}

export function timeToMonth(time) {
    let date = new Date(time * 1000)
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) ;
}