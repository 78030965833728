import React from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Checkbox, Layout, PageHeader, Card, message, Radio, Upload, Tag, Select } from 'antd';
import { formlayout, apiUrl, ossUrl, formlayoutModal } from '../../../global';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css'
import RequestComponent, { GetQuery } from '../../../compontents/common/RequestComponent';


export default class ScenicTicketAdd extends React.Component {
    constructor(props) {
        super(props);
        this.formModalRef = React.createRef();
        this.initialValues = this.getInitialValues();
        this.state = {
            data: [],
            scenics: [],
        }
        this.formRef = React.createRef();
    }


    getInitialValues = () => {
        return {
            name: '',
            price: '0.00',
            sort: 0,
            buy_rule: '',
            refund_rule: '',
            scenic_id: 0,
            id: 0
        };
    }

    componentDidMount = () => {
        let id = this.props.id;
        RequestComponent('scenic/ticketAdd', {
            method: "GET",
            data: { id: id }
        }).then(r => {
            let state = {};
            if (r.data.data.data != null) { 
                this.state.title = '编辑门票';
                let field = r.data.data.data;
                this.formRef.current.setFieldsValue({
                    id: field.id,
                    name: field.name,
                    scenic_id: field.scenic_id,
                    sort: field.sort,
                    price: field.price,
                    buy_rule: field.buy_rule,
                    refund_rule: field.refund_rule
                });
            }
            state['scenics'] = r.data.data.scenics;
            this.setState(state);
        });
    }

    submit = (v) => {
        if (v.name != undefined) {
            RequestComponent('scenic/ticketAdd', {
                data: v
            }).then(r => {
                if (r.data.code == 0) {
                    RequestComponent('log/add', {
                        data: {
                            content: this.state.title + ": " + v.name
                        }
                    });
                    message.success({
                        content: '提交成功',
                        style: {
                            marginTop: '10vh',
                        },
                    });
                    this.props.closeModal();
                    this.props.reload();
                }
            })
        }
    }

    render() {
        let html = (
            <Layout.Content>
                    {this.state.data &&
                        <Form {...formlayoutModal.form} onFinish={this.submit} ref={this.formRef} initialValues={this.initialValues} >
                            <Form.Item label="关联景点id" name="scenic_id"  rules={[{ required: true }]}>
                                {this.state.scenics.length > 0 &&
                                    <Select showSearch>
                                        <Select.Option key={0} value={0}>无</Select.Option>
                                        {this.state.scenics.map((i, e) => (
                                            <Select.Option key={i.id} value={i.id}>{i.name}</Select.Option>
                                        ))}
                                    </Select>
                                }
                            </Form.Item>
                            <Form.Item label="排序" name="sort" >
                                <Input autoComplete="off"></Input>
                            </Form.Item>
                            <Form.Item label="名称" name="name" rules={[{ required: true }]}>
                                <Input autoComplete="off"></Input>
                            </Form.Item>
                            <Form.Item label="价格" name="price" rules={[{ required: true }]}>
                                <Input autoComplete="off"></Input>
                            </Form.Item>
                            <Form.Item label="购买规则" name="buy_rule" rules={[{ required: true }]}>
                                <Input.TextArea></Input.TextArea>
                            </Form.Item>
                            <Form.Item label="退款规则" name="refund_rule" rules={[{ required: true }]}>
                                <Input.TextArea></Input.TextArea>
                            </Form.Item>
                            <Form.Item hidden name="id">
                                <Input />
                            </Form.Item>
                            <Form.Item wrapperCol={formlayoutModal.button}>
                                <Button htmlType="submit" type="primary">提交</Button>
                            </Form.Item>
                        </Form>
                    }
            </Layout.Content>
        );
        return (
            html
        )
    }
}