import React, { Children } from 'react';
import Layout, { Content } from 'antd/lib/layout/layout';
import Sider from 'antd/es/layout/Sider';
import {DeleteOutlined, SettingOutlined, MenuUnfoldOutlined, MenuFoldOutlined, FireOutlined, DesktopOutlined, UserOutlined, VideoCameraOutlined, BranchesOutlined, WechatOutlined}  from '@ant-design/icons';
import { Breadcrumb, Button, Divider, Dropdown, Flex, Form, Input, Menu, message, Modal, Row, Space, Tabs } from 'antd';
import { Header } from 'antd/es/layout/layout';
import Setting from '../setting/Setting';
import { formlayout, formlayoutModal, segments } from '../../global';
import RequestComponent from '../../compontents/common/RequestComponent';
import LogList from '../log/LogList';
import Site from '../system/Site';
import Admin from '../system/admin/Admin';
import AdminGroup from '../system/admin/AdminGroup';
import Node from '../system/admin/Node';
import UserList from '../user/list/UserList';
import ScenicList from '../scenic/list/ScenicList';
import Category from '../operate/category/Category';
import Config from '../operate/config/Config';
import Official from '../wechat/official/Official';
import Notice from '../operate/notice/Notice';
import ScenicTicket from '../scenic/ticket/ScenicTicket';

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.login = this.isLogin(); // 登录信息
        this.nodeKeys = this.login.node; // 节点
        this.manyTabs = this.getManyTabs(); // 多标签
        this.defaultOpenKey = 'user'; // 默认打开的菜单
        this.defaultKey = 'user/list'; // 默认选中的菜单
        this.currentMenu = {}; // 当前点击的左侧菜单
        this.tabsMenuVisible = true;
        if (!this.manyTabs) {
            this.defaultOpenKey = segments[1];
            if (segments[2] != null) {
                this.defaultKey = segments[1] + '/' + segments[2];
            } else {
                if (segments[1] == 'setting') {
                    this.defaultKey = 'setting';
                }
            }
        }
        this.pwdModalRef = React.createRef();
        this.tabsRef = React.createRef();
        this.breadcrumb = [];
        // 头部下拉导航
        this.headerMenu = [
            {
              key: 'pwd',
              label: (
                <a>修改密码</a>
              ),
            },
            {
                key: 'logout',
                label: (
                  <a>退出系统</a>
                ),
              },
        ];
        // 标签右键菜单
        this.tabsMenu = [
            {
                key: 'other',
                label: (
                 <a>关闭其他标签</a>
                ),
            },
        ];
        // 菜单渲染的组件
        this.menuComponents = {
            'user/list': <UserList nodeKeys={this.nodeKeys} />,
            'scenic/scenicList': <ScenicList nodeKeys={this.nodeKeys} />,
            'scenic/ticket': <ScenicTicket nodeKeys={this.nodeKeys} />,
            'operate/category': <Category nodeKeys={this.nodeKeys} />,
            'operate/config': <Config nodeKeys={this.nodeKeys} />,
            'operate/notice': <Notice nodeKeys={this.nodeKeys} />,
            'wechat/official': <Official nodeKeys={this.nodeKeys} />,
            'system/site': <Site />,
            'system/admin': <Admin nodeKeys={this.nodeKeys} />,
            'system/group': <AdminGroup nodeKeys={this.nodeKeys} />,
            'system/node': <Node nodeKeys={this.nodeKeys} />,
            'setting': <Setting />,
            'log/list': <LogList />,
        };
        if (this.manyTabs) {
        // 菜单导航
            this.menus = {
                'user/list': {key: 'user/list', label: '用户列表', children: ''},
                'scenic/scenicList': {key: 'scenic/scenicList', label: this.nodeKeys['scenic/scenicList'].NodeName, children: ''},
                'scenic/ticket': {key: 'scenic/ticket', label: this.nodeKeys['scenic/ticket'].NodeName, children: ''},
                'operate/category': {key: 'operate/category', label: this.nodeKeys['operate/category'].NodeName, children: ''},
                'operate/config': {key: 'operate/config', label: this.nodeKeys['operate/config'].NodeName, children: ''},
                'operate/notice': {key: 'operate/notice', label: this.nodeKeys['operate/notice'].NodeName, children: ''},
                'wechat/official': {key: 'wechat/official', label: this.nodeKeys['wechat/official'].NodeName, children: ''},
                'system/site': {key: 'system/site', label: this.nodeKeys['system/site'].NodeName, children: ''},
                'system/admin': {key: 'system/admin', label: this.nodeKeys['system/admin'].NodeName, children: ''},
                'system/group': {key: 'system/group', label: this.nodeKeys['system/group'].NodeName, children: ''},
                'system/node': {key: 'system/node', label: this.nodeKeys['system/node'].NodeName, children: ''},
                'setting': {key: 'setting', label: '后台配置', children: ''},
                'log/list': {key: 'log/list', label: this.nodeKeys['log/list'].NodeName, children: ''}
            };
            this.menus[this.defaultKey].children = this.renderMenuContent(this.menuComponents[this.defaultKey]);
        }
        // 菜单列表
        this.menu = this.renderMenu();
        this.state = {
            // 菜单收回
            collapsed: false,
            // 菜单默认选中
            menuDefault: [
                {openKey: this.defaultOpenKey, key: this.defaultKey, label: '后台配置'}
            ],
            // 当前展开的菜单项
            openKeys: [this.defaultOpenKey],
            selectedKeys: [this.defaultKey],
            // 多标签
            menuTabs: [
                {key:'deleteTabs', label: <DeleteOutlined />, closable: false},
                this.manyTabs ? this.menus[this.defaultKey] : '', // 默认第一个
            ],
            // 当前激活的tab
            activeKey: this.defaultKey,
            // 是否打开修改密码弹窗
            openPwdModal: false,
        };
    }
    componentDidMount() {
    }
    // 是否登录
    isLogin() {
        let login = JSON.parse(localStorage.getItem("login"))
        if (!login || Date.parse(new Date()) / 1000 - login.admin.LoginTime > 7 * 86400) {
            return window.location.href = "/login"
        }
        return login
    }

    // 获取多标签模式状态
    getManyTabs = () => {
        let manyTabs = true;
        const res = localStorage.getItem('many_tabs');
        if (res != null && res == 'false') {
            manyTabs = false;
        }
        return manyTabs;
    }

    // 渲染导航组件
    renderMenuContent = (html) => {
        let componentPath = this.defaultKey;
        if (this.manyTabs) {
            if (this.currentMenu.key == undefined) {
                this.breadcrumb.push({title: '后台设置'});
            } else {
                this.breadcrumb = [];
                if (this.currentMenu.key == 'setting') {
                    this.breadcrumb.push({title: '后台设置'});
                } else {
                    const currentMenu = this.currentMenu.key.split('/');
                    this.breadcrumb.push({title: this.nodeKeys[currentMenu[0]].NodeName});
                    if (currentMenu[1] != undefined) {
                        this.breadcrumb.push({title: this.nodeKeys[this.currentMenu.key].NodeName});
                    }
                }
            }
            return [<Breadcrumb items={this.breadcrumb} style={{margin: '0px 0px 20px 0px'}}></Breadcrumb>,<Content style={{
                padding: '20px',
                backgroundColor: 'white',
            }}>
                {html}
            </Content>]
        } else {
            this.breadcrumb = [];
            if (segments[1] == 'setting') {
                this.breadcrumb.push({title: '后台设置'});
            } else {
                if (segments[1] == undefined || segments[1] == '') {
                    window.location.href = '/' + this.defaultKey;
                } else {
                    this.breadcrumb.push({title: this.nodeKeys[segments[1]].NodeName});
                    if (segments[2] != undefined) {
                        this.breadcrumb.push({title: this.nodeKeys[segments[1] + '/' + segments[2]].NodeName});
                    }
                }
            }
            return [<Breadcrumb items={this.breadcrumb} style={{margin: '20px'}}></Breadcrumb>,<Content style={{
                padding: '20px',
                backgroundColor: 'white',
            }}>
                {this.menuComponents[componentPath]}
            </Content>]
        }
    }

    // 设置菜单项
    menuItem (key, label, icon, children) {
        return {
            key: key,
            label: label,
            icon: icon,
            children: children
        }
    }

    // 渲染菜单
    renderMenu () {
        let menu = [];
        // 用户
        if (this.nodeKeys['user']) {
            let children = [];
            menu.push(this.menuItem('user',this.nodeKeys['user'].NodeName, <UserOutlined />,children));
            if (this.nodeKeys['user/list']) {
                children.push(this.menuItem('user/list', this.nodeKeys['user/list'].NodeName));
            }
        }
        // 景区
        if (this.nodeKeys['scenic']) {
            let children = [];
            menu.push(this.menuItem('scenic',this.nodeKeys['scenic'].NodeName, <VideoCameraOutlined />, children));
            if (this.nodeKeys['scenic/scenicList']) {
                children.push(this.menuItem('scenic/scenicList', this.nodeKeys['scenic/scenicList'].NodeName));
            }
            if (this.nodeKeys['scenic/ticket']) {
                // children.push(this.menuItem('scenic/ticket', this.nodeKeys['scenic/ticket'].NodeName));
            }
        }
        // 运营
        if (this.nodeKeys['operate']) {
            let children = [];
            menu.push(this.menuItem('operate',this.nodeKeys['operate'].NodeName, <BranchesOutlined />, children));
            if (this.nodeKeys['operate/notice']) {
                children.push(this.menuItem('operate/notice', this.nodeKeys['operate/notice'].NodeName));
            }
            if (this.nodeKeys['operate/category']) {
                children.push(this.menuItem('operate/category', this.nodeKeys['operate/category'].NodeName));
            }
            if (this.nodeKeys['operate/config']) {
                children.push(this.menuItem('operate/config', this.nodeKeys['operate/config'].NodeName));
            }
        }
        // 微信管理
        if (this.nodeKeys['wechat']) {
            let children = [];
            menu.push(this.menuItem('wechat',this.nodeKeys['wechat'].NodeName, <WechatOutlined />, children));
            if (this.nodeKeys['wechat/official']) {
                children.push(this.menuItem('wechat/official', this.nodeKeys['wechat/official'].NodeName));
            }
        }
        // 系统
        if (this.nodeKeys['system']) {
            let children = [];
            if (this.nodeKeys['system/site']) {
                children.push(this.menuItem('system/site', this.nodeKeys['system/site'].NodeName));
            }
            if (this.nodeKeys['system/admin']) {
                children.push(this.menuItem('system/admin', this.nodeKeys['system/admin'].NodeName));
            }
            if (this.nodeKeys['system/group']) {
                children.push(this.menuItem('system/group', this.nodeKeys['system/group'].NodeName));
            }
            if (this.nodeKeys['system/node']) {
                children.push(this.menuItem('system/node', this.nodeKeys['system/node'].NodeName));
            }
            menu.push(this.menuItem(this.nodeKeys['system'].NodeUrl ? this.nodeKeys['system'].NodeUrl : this.nodeKeys['system'].NodeKey, this.nodeKeys['system'].NodeName, <DesktopOutlined />, children));
        }
        menu.push(this.menuItem('setting', '后台配置', <SettingOutlined />));
        if (this.nodeKeys['log']) {
            menu.push(this.menuItem(this.nodeKeys['log'].NodeUrl, this.nodeKeys['log'].NodeName, <FireOutlined />, [this.menuItem('log/list', this.nodeKeys['log/list'].NodeName)]));
        }
        return menu;
    }

    // 设置菜单是否缩小
    setCollapsed = (collapsed) => {
        this.setState({
            collapsed: collapsed
        });
    }
    // 标签编辑点击
    onEdit = (targetKey, action) => {
        const newTabs = this.state.menuTabs.filter((item) => item.key != targetKey);
        this.setState({
            menuTabs: newTabs
        });
    }

    // 标签点击
    onTabClick = (targetKey) => {
        if (targetKey == 'deleteTabs') {
            this.state.menuTabs = this.state.menuTabs.filter(item => {
                if (item.key != 'deleteTabs' && item.key != this.state.activeKey) {
                    return false;
                } else {
                    return true;
                }
            });
            this.setState({
                menuTabs: this.state.menuTabs
            });
            return
        } else {
            this.setState({
                activeKey: targetKey,
                selectedKeys: [targetKey],
                openKeys: [targetKey.split('/')[0]]
            });
        }
    }
    // 菜单展开
    onOpenChange = (a) => {
        this.setState({
            openKeys: a
        });
    }
    
    // 菜单点击
    clickMenu = (menu) => {
        this.currentMenu = menu;
        if (this.manyTabs) {
            let exists = false;
            for (let i = 0; i < this.state.menuTabs.length; i++) {
                if (this.state.menuTabs[i].key == menu.key) {
                    exists = true;
                    break;
                }
            }
            if (exists) {
                // 存在, 激活tab
                this.setState({
                    activeKey: menu.key,
                    key: menu.key,
                    selectedKeys: menu.key
                });
            } else {
                // 不存在, 新增tab
                this.menus[menu.key].children = this.renderMenuContent(this.menuComponents[menu.key]);
                let newTabs = [
                    this.menus[menu.key]
                ];
                this.state.menuTabs = this.state.menuTabs.concat(newTabs);
                this.setState({
                    menuTabs: this.state.menuTabs,
                    activeKey: menu.key,
                    selectedKeys: menu.key
                });
            }
        } else {
            window.location.href = '/' + menu.key;
        }
    }

    // 下拉菜单点击事件
    dropdownClick = (key) => {
        if (key.key == 'logout') {
            localStorage.removeItem("login")
            window.location.href = "/login";
        } else if (key.key == 'pwd') {
            this.setState({
                openPwdModal: true
            });
        }
    }

    // 关闭修改密码弹窗
    closePwdModal = () => {
        this.setState({
            openPwdModal: false
        });
    }

    // 表单提交
    onFinish = (v) => {
        if (v.pwd != "" && v.repwd != "") {
            if (v.pwd != v.repwd) {
                message.error('双次输入的密码不一致');
            } else {
                RequestComponent('account/pwd', {
                    data:v
                }).then(r => {
                    if (r.data.code == 0) {
                        this.props.history.push('/login')
                    }
                })
            }
        }
    }

    // Tabs右键菜单
    handleContextMenu = (e) => {
        e.preventDefault(); // 阻止浏览器默认的右键菜单  
        const rect = e.target.getBoundingClientRect();
        this.tabsRef.current.style.left = `${rect.left + window.pageXOffset}px`;
        this.tabsRef.current.style.top = `${rect.bottom + window.pageYOffset}px`;
        alert(rect.left)
    }

    render() {
        // 有一个特性，当变量与组件属性名称一致时，用{{items}} = {{items: items}}
        // const items = [
        //     {
        //       key: 'pwd',
        //       label: (
        //         <a>修改密码</a>
        //       ),
        //     },
        //     {
        //         key: 'logout',
        //         label: (
        //           <a>退出系统</a>
        //         ),
        //       },
        // ];
        const element = (
            <Layout style={{
                flexDirection: 'initial'
            }}>
                <Sider id="tabs" trigger={null} collapsible collapsed={this.state.collapsed} style={{
                    // height: '100vh',
                    
                    // position: 'fixed',
                    // overflow: 'auto',
                    // height: '100vh',
                    // left: 0,
                    // top: 0,
                    // bottom: 0,
                }}>
                    <div style={{
                        color: 'white',
                        height: '32px',
                        lineHeight: '32px',
                        margin: '16px',
                        background: 'rgba(255, 255, 255, .2)',
                        borderRadius: '6px',
                        textAlign: 'center',
                        cursor: 'pointer'
                    }} onClick={() => {
                        window.open('/dashboard/datas','_blank');
                    }}>智慧大屏</div>
                    <div style={{
                        color: 'white',
                        height: '32px',
                        lineHeight: '32px',
                        margin: '16px',
                        background: 'rgba(255, 255, 255, .2)',
                        borderRadius: '6px',
                        textAlign: 'center',
                        cursor: 'pointer'
                    }} onClick={() => {
                        window.open('/dashboard/fullview','_blank');
                    }}>全景大屏</div>
                    <Menu style={{height: '100vh'}} theme="dark" mode="inline" items={this.menu} openKeys={this.state.openKeys} selectedKeys={this.state.selectedKeys} defaultOpenKeys={[this.state.menuDefault[0].openKey]} defaultSelectedKeys={[this.state.menuDefault[0].key]} onClick={this.clickMenu} onOpenChange={this.onOpenChange} />
                </Sider>
 
                <Layout >
                    <Header style={{paddingLeft: 10}}>
                        <Flex justify="space-between" align="center">
                            <Button
                                type="text"
                                icon={this.state.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                onClick={() => this.setCollapsed(!this.state.collapsed)}
                                style={{
                                    fontSize: '16px',
                                    color: '#fff'
                                }}
                            />
                            
                            <Dropdown menu={{items: this.headerMenu, onClick: this.dropdownClick}}>
                                <a onClick={(e) => e.preventDefault()} style={{color: 'white'}}>
                                <Space>
                                    {this.login.admin.AdminUser}
                                </Space>
                                </a>
                            </Dropdown>


                            {/* <Dropdown menu={[
                                {
                                    key: 'pwd',
                                    label: 'pwd'
                                }
                            ]}>
                                <a style={{color: '#fff'}} >{this.login.admin.AdminUser}</a>
                            </Dropdown> */}
                        </Flex>
                    </Header>
                    {this.manyTabs ?
                        <Tabs 
                            style={{padding: 10}}
                            size='small'
                            items={this.state.menuTabs}
                            type="editable-card"
                            hideAdd
                            onEdit={this.onEdit}
                            activeKey={this.state.activeKey}
                            onTabClick={this.onTabClick}
                        />
                        :
                        this.renderMenuContent()
                    }

                </Layout>


                {/* 修改密码弹窗 */}
                <Modal title="修改密码" open={this.state.openPwdModal} onCancel={this.closePwdModal} width={600} footer={null}>
                    <Form {...formlayoutModal.form} onFinish={this.onFinish} ref={this.pwdModalRef}>
                        <Form.Item label="新密码" name="pwd" rules={[{ required: true }]}>
                            <Input.Password></Input.Password>
                        </Form.Item>
                        <Form.Item label="确认新密码" name="repwd" rules={[{ required: true }]}>
                            <Input.Password></Input.Password>
                        </Form.Item>
                        <Form.Item wrapperCol={formlayoutModal.button}>
                            <Button htmlType="submit" type="primary">提交</Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </Layout>
        );
        return (
            element
        )
    }
}