import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'dayjs/locale/zh-cn';
import zhCN from 'antd/lib/locale/zh_CN';
import { ConfigProvider } from 'antd';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { GlobalReducer } from './reducers/GlobalReducer';


let store = createStore(GlobalReducer)

ReactDOM.render(
	// <React.StrictMode>
		<ConfigProvider locale={zhCN} theme={{
			token: {
			// 	hoverBorderColor: '#000',
			// 	colorErrorBorder: '#dc4446',
			// 	colorErrorOutline: '#dc4446',
			// 	colorErrorBorder: '#dc4446',
			// 	colorErrorBorderHover: '#dc4446',
			// 	colorBorder: '#808080',
				// colorTextBase: '#fff',
				// colorBgBase: '#1f1f1f',
			// 	colorPrimary: '#00B96B',
				// colorPrimaryBg: '#1f1f1f',
				borderRadius: 4,
				borderRadiusLG: 4,
			},
			// components: {
			// 	Message: {
			// 		contentBg: "rgb(180, 81, 81)",
			// 		colorError: '#000'
			// 	}
			// }
		}} >
			<Provider store={store}>
				<App />
			</Provider>
		</ConfigProvider>,
	// </React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
