import React from 'react';
import { Button, Form, Input, Row, Table, Layout, Col, Space, Divider, Select, Popconfirm, Card, Modal, message, Tag } from 'antd';
import RequestComponent from '../../../compontents/common/RequestComponent';
import { timeToDateTime } from '../../../compontents/common/DateComponent'
import { formlayout, formlayoutModal, getRowClassName, searchFormCol } from "../../../global";
import {ReloadOutlined }  from '@ant-design/icons';

export default class Category extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.formModalRef = React.createRef();
        this.initialValues = this.getInitialValues();
        // 查询参数
        this.queryParam = {
            page: 1,
            type: 0,
            limit: 20
        };
        this.state = {
            data: [],
            openModal: false,
            modalTitle: '添加',
            pagination: {
                current: 1,
                position: ["bottomLeft"],
                hideOnSinglePage: true,
                total: 0,
                showQuickJumper: true,
                pageSize: 20,
                showSizeChanger: false,
                showTotal: (total) => {return '共' + total + '条'}
            }
        }
    }

    componentDidMount = () => {
        this.queryData();
    };

    getInitialValues = () => {
        return {
            category_name: '',
            category_sort: '',
            category_type: '',
            id: 0
        };
    }

    queryData = (data) => {
        if (data == undefined) {
            data = this.queryParam;
        }
        RequestComponent("operate/category", {
            method: "GET",
            data: data
        }).then(r => {
            if (r.data.code == 0) {
                this.state.pagination.total = r.data.data.count;
                this.setState({
                    data: r.data.data.list,
                    pagination: this.state.pagination
                });
            }
        });
    };

    handleTableChange = (page, filter, sort) => {
        let data = this.formRef.current.getFieldsValue();
        if (page.current != this.state.pagination.current) {
            this.state.pagination.current = page.current;
            // this.state.queryParam.page = page.current
            data.page = page.current;
        }
        this.queryData(data)
    };

    // 表单提交
    submit = (v) => {
        this.queryData(v)
    }

    // 重置查询
    reload = () => {
        this.queryData();
        this.onReset();
    }

    // 重置表单
    onReset = (v) => {
        this.formModalRef.current.setFieldsValue(this.queryParam);
    }

    // 显示弹窗
    showModal = (e) => {
        let id = e.currentTarget.dataset.id || 0;
        let data = {
            openModal: true
        };
        RequestComponent('operate/categoryAdd', {
            method: "GET",
            data: {
                id: id
            }
        }).then(r => {
            if (r.data.data != null) {
                data['modalTitle'] = '编辑';
                this.formModalRef.current.setFieldsValue({
                    id: r.data.data.CategoryId,
                    category_name: r.data.data.CategoryName,
                    category_sort: r.data.data.CategorySort,
                    category_type: r.data.data.CategoryType
                });
            }
            this.setState(data);
        });
    }

    // 关闭弹窗
    closeModal = () => {
        this.formModalRef.current.setFieldsValue(this.initialValues);
        this.setState({
            openModal: false
        });
    }

    // 表单提交
    onFinish = (v) => {
        if (v.category_name != '') {
            RequestComponent('operate/categoryAdd', {
                data: v
            }).then(r => {
                if (r.data.code == 0) {
                    let logText = '添加分类：' + v.CategoryName;
                    if (v.CategoryId != undefined) {
                        logText = '编辑分类：' + v.CategoryName;
                    }
                    RequestComponent('log/add', {
                        data: {
                            content: logText
                        }
                    });
                    message.success({
                        content: '提交成功',
                        style: {
                            marginTop: '10vh',
                        }
                    });
                    this.closeModal();
                    this.reload();
                }
            })
        }
    }

    /**
     * 删除
     * @param {*} v
     */
    onDel = (v) => {
        if (v.CategoryId > 0) {
            RequestComponent('operate/categoryDelete', {
                data: {
                    id: v.CategoryId
                }
            }).then(r => {
                if (r.data.code == 0) {
                    RequestComponent('log/add', {
                        data: {
                            content: '删除分类：' + v.CategoryName
                        }
                    });
                    this.setState({
                        data: this.state.data.filter(item => item.CategoryId != v.CategoryId)
                    });
                } else {
                    message.error({
                        content: r.data.msg,
                        style: {
                            marginTop: '10vh',
                        }
                    });
                }
            });
        }
    };


    render() {
        let columns = [
            {
                title: 'ID',
                dataIndex: 'CategoryId',
                width: 100
            },
            {
                title: '名称',
                dataIndex: 'CategoryName',
                width: 200
            },
            {
                title: '类型',
                dataIndex: 'CategoryType',
                width: 100,
                render: (v) => {
                    if (v == 1) {
                        return <Tag color="blue">文章</Tag>
                    } else if (v == 2) {
                        return <Tag color="volcano">小说</Tag>
                    } else if (v == 3) {
                        return <Tag color="gold">漫画</Tag>
                    }
                }
            },
            {
                title: '内容数量',
                dataIndex: 'ContentCount',
                width: 100
            },
            {
                title: '操作',
                render: (v) => {
                    return (
                        <Row>
                            <Space size={10}>
                                {this.props.nodeKeys["operate/categoryAdd"] &&
                                    <Button type="primary" size="small" data-id={v.CategoryId} onClick={this.showModal}>编辑</Button>
                                }
                            {this.props.nodeKeys["operate/categoryDelete"] &&
                                <Popconfirm title="确定删除?" onConfirm={() => this.onDel(v)}>
                                    <Button type="primary" danger size="small" >删除</Button>
                                </Popconfirm>
                            }
                            </Space>
                        </Row>
                    )
                }
            }
        ];
        let html = (
            <Layout.Content>
                <Space>
                    {this.props.nodeKeys['operate/categoryAdd'] && 
                        <Button type="primary" style={{marginBottom: 20}} onClick={this.showModal}>添加</Button>
                    }
                    <Button shape="circle" icon={<ReloadOutlined />} style={{marginBottom: 20}} onClick={this.reload}></Button>
                </Space>
                <Table rowClassName={getRowClassName} rowKey="AdminId" columns={columns} dataSource={this.state.data} scroll={{ x: 'max-content' }} pagination={false} size="small"></Table>

                <Modal title={this.state.modalTitle}  open={this.state.openModal} onCancel={this.closeModal} width={600} footer={null} forceRender={true} maskClosable={false}>
                    <Form {...formlayoutModal.form} onFinish={this.onFinish} ref={this.formModalRef} initialValues={this.initialValues}>
                    <Form.Item label="名称" name="category_name" rules={[{ required: true }]} >
                            <Input autoComplete="off"></Input>
                        </Form.Item>
                        <Form.Item label="排序"  name="category_sort">
                            <Input autoComplete="off"></Input>
                        </Form.Item>
                        <Form.Item label="类型" name="category_type" rules={[{ required: true }]}>
                            <Select >
                                <Select.Option key={1} value={1}>文章</Select.Option>
                                {/* <Select.Option key={2} value={2}>小说</Select.Option>
                                <Select.Option key={3} value={3}>漫画</Select.Option>
                                <Select.Option key={4} value={4}>影视</Select.Option> */}
                            </Select>
                        </Form.Item>
                        <Form.Item hidden name="id">
                            <Input name="id" />
                        </Form.Item>
                        <Form.Item wrapperCol={formlayoutModal.button}>
                            <Button htmlType="submit" type="primary">提交</Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </Layout.Content>
        );
        return (
            html
        );
    }
}