import React from 'react';
import { Button, Form, Input, Row, Table, Layout, Col, Space, Divider, Select, Popconfirm, Card, Modal, message, Tree } from 'antd';
import RequestComponent from '../../../compontents/common/RequestComponent';
import { timeToDateTime } from '../../../compontents/common/DateComponent'
import { formlayout, formlayoutModal, getRowClassName, searchFormCol } from "../../../global";
import {ReloadOutlined }  from '@ant-design/icons';

export default class AdminGroup extends React.Component {
    constructor(props) {
        super(props);
        this.formModalRef = React.createRef();
        this.initialValues = this.getInitialValues();
        this.state = {
            data: [],
            node: [],
            treeData: [],
            checkedKeys: [],
            openModal: false,
            modalTitle: '添加',
            validate: {
                admin_user: {}
            },
            pagination: {
                current: 1,
                position: ["bottomLeft"],
                hideOnSinglePage: true,
                total: 0,
                showQuickJumper: true,
                pageSize: 20,
                showSizeChanger: false,
            }
        }
    }

    componentDidMount = () => {
        this.queryData();
    };

    getInitialValues = () => {
        return {
            group_name: '',
            node_keys: '',
            id: 0
        };
    }

    queryData = () => {
        RequestComponent('system/group', {
            method: "GET"
        }).then(r => {
            if (r.data.code == 0) {
                this.setState({
                    data: r.data.data
                })
            }
        });
    };

    handleTableChange = (page, filter, sort) => {
        if (page.current != this.state.pagination.current) {
            this.state.pagination.current = page.current;
            // this.state.queryParam.page = page.current
            let data = this.formRef.current.getFieldsValue();
            data.page = page.current;
            this.queryData(data)
        }
    };


    // 显示弹窗
    showModal = (e) => {
        let id = e.currentTarget.dataset.id || 0;
        let data = {
            id: id
        };
        RequestComponent('system/groupAdd', {
            method: "GET",
            data: data
        }).then(r => {
            let nodes = r.data.data.nodes
            let data = {
                openModal: true,
                modalTitle: '编辑',
                treeData: [],
                checkedKeys : []
            };
            for (let item in nodes) {
                let n = nodes[item];
                if (n.NodePid == 0) {
                    n['title'] = n.NodeName;
                    n['key'] = n.NodeKey;
                    n['children'] = [];
                    for (let item in nodes) {
                        let nc = nodes[item];
                        if (nc.NodePid == n.NodeId) {
                            nc['title'] = nc.NodeName;
                            nc['key'] = nc.NodeKey;
                            nc['children'] = [];
                            for (let item in nodes) {
                                let ncc = nodes[item];
                                if (ncc.NodePid == nc.NodeId) {
                                    ncc['title'] = ncc.NodeName;
                                    ncc['key'] = ncc.NodeKey;
                                    nc.children.push(ncc)
                                }
                            }
                            n.children.push(nc)
                        }
                    }
                    data.treeData.push(n)
                }
            }
            if (r.data.data.data) {
                this.formModalRef.current.setFieldsValue({
                    group_name: r.data.data.data.GroupName,
                    node_keys: r.data.data.data.NodeKeys,
                    id: r.data.data.data.GroupId
                });
                data.checkedKeys = r.data.data.data.NodeKeys.split(',');
            }
            this.setState(data);
        });
    }

    // 关闭弹窗
    closeModal = () => {
        this.formModalRef.current.setFieldsValue(this.initialValues);
        this.setState({
            openModal: false
        });
    }

    // 重置查询
    reload = () => {
        this.queryData();
    }

    onCheck = (checkedKeys, info) => {
        this.state.checkedKeys = checkedKeys;
        this.formModalRef.current.setFieldsValue({
            node_keys: this.state.checkedKeys.checked.join(',')
        });
    }

    // 表单提交
    onFinish = (v) => {
        RequestComponent('system/groupAdd', {
            data: v
        }).then(r => {
            if (r.data.code == 0) {
                let logText = '添加管理组：' + v.group_name;
                if (v.id != undefined) {
                    logText = '编辑管理组：' + v.group_name;
                }
                RequestComponent('log/add', {
                    data: {
                        content: logText
                    }
                });
                message.success({
                    content: '提交成功',
                    style: {
                        marginTop: '10vh',
                    },
                });
                this.closeModal();
                this.reload();
            }
        })
    }

    /**
     * 删除
     * @param {*} v
     */
    onDel = (v) => {
        if (v.AdminId > 0) {
            RequestComponent('system/adminDelete', {
                data: {
                    id: v.AdminId
                }
            }).then(r => {
                if (r.data.code == 0) {
                    RequestComponent('log/add', {
                        data: {
                            content: '删除管理员：' + v.AdminUser
                        }
                    });
                    this.setState({
                        data: this.state.data.filter(item => item.AdminId != v.AdminId)
                    });
                }
            });
        }
    };


    render() {
        let columns = [
            {
                title: 'ID',
                dataIndex: 'GroupId',
                width: 100
            },
            {
                title: '名称',
                dataIndex: 'GroupName',
            },
            {
                title: '操作',
                render: (v) => {
                    return (
                        <Row>
                            {this.props.nodeKeys['system/groupAdd'] && 
                                <Button type="primary" size="small" data-id={v.GroupId} onClick={this.showModal}>编辑</Button>
                            }
                            &nbsp;
                            {this.props.nodeKeys['system/groupDelete'] && v.AdminId > 1 &&
                                <Popconfirm title="确定删除?" onConfirm={() => this.onDel(v)}>
                                    <Button type="primary" danger size="small">删除</Button>
                                </Popconfirm>
                            }
                        </Row>
                    );
                }
            }
        ];
        let html = (
            <Layout.Content>
                {this.props.nodeKeys['system/groupAdd'] && 
                    <Button type="primary" style={{marginBottom: 20}} onClick={this.showModal}>添加</Button>
                }
                <Table rowClassName={getRowClassName} rowKey="GroupId" columns={columns} dataSource={this.state.data} scroll={{ x: 'max-content' }} pagination={false} size="small"></Table>

                <Modal title={this.state.modalTitle}  open={this.state.openModal} onCancel={this.closeModal} width={600} footer={null} forceRender={true} maskClosable={false}>
                    <Form {...formlayoutModal.form} onFinish={this.onFinish} ref={this.formModalRef} initialValues={this.initialValues}>
                        <Form.Item label="名称" name="group_name" rules={[{ required: true }]} >
                            <Input autoComplete="off"></Input>
                        </Form.Item>
                        <Form.Item label="选择权限">
                            {this.state.treeData.length > 0 &&
                                <Tree treeData={this.state.treeData} checkable defaultExpandAll={true} defaultCheckedKeys={this.state.checkedKeys} onCheck={this.onCheck} checkStrictly></Tree>
                            }
                        </Form.Item>
                        <Form.Item hidden name="node_keys" >
                            <Input autoComplete="off"></Input>
                        </Form.Item>
                        <Form.Item hidden name="id">
                            <Input  />
                        </Form.Item>
                        <Form.Item wrapperCol={formlayoutModal.button}>
                            <Button htmlType="submit" type="primary">提交</Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </Layout.Content>
        );
        return (
            html
        );
    }
}