import React from 'react';
import { Alert, Button, Form, Input, message } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import styles from './Login.module.css';
import RequestComponent from '../../compontents/common/RequestComponent';

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.min = Number(1);
        this.max = Number(10);
        this.state = {
            showError: false,
            num1: Math.floor(Math.random() * (this.max - this.min + 1)) + this.min,
            num2: Math.floor(Math.random() * (this.max - this.min + 1)) + this.min,
        }
        localStorage.removeItem("login")
    }

    submit = (data) => {
        if (data.admin_user !== '' && data.admin_pwd !== '' && data.check_code !== '') {
            //校验验证码
            if (data.check_code == this.state.num1 + this.state.num2) {
                //登录
                RequestComponent('login/to', {
                    data: {
                        admin_user: data.admin_user,
                        admin_pwd: data.admin_pwd
                    }
                }).then(r => {
                    if (r.data.code == 0) {
                        localStorage.setItem("login", JSON.stringify(r.data.data))
                        RequestComponent('log/add', {
                            data: {
                                content: '登录系统'
                            }
                        });
                        window.location.href = "/"
                    } else {
                        this.setState({
                            showError: true
                        });
                    }
                });
            } else {
                //验证码错误
                message.error({
                    content: '验证码错误',
                    style: {
                        marginTop: '10vh',
                    },
                });
            }
        }
    }

    //点击刷新验证码
    checkRefresh = () => {
        this.setState({
            num1: Math.floor(Math.random() * (this.max - this.min + 1)) + this.min,
            num2: Math.floor(Math.random() * (this.max - this.min + 1)) + this.min,
        });
    }

    render() {
        const element = (
            <Layout>
                <Content className={styles['antd-login-layout']}>
                    <div className={styles["antd-login-layout-content"]}>
                        <div className={styles["antd-login-layout-content-top"]}>
                            <div className={styles["antd-login-layout-content-header"]}>
                                {/* <img alt="logo" className={styles["antd-login-layout-content-logo"]} src="/logo.f0355d39.svg" /> */}
                                <span className={styles["antd-login-layout-content-title"]}>红杨度假区管理系统</span>
                            </div>
                            <div className={styles["antd-login-layout-content-desc"]}></div>
                        </div>
                    </div>
                    <div className={styles["antd-login-layout-main"]}>
                        <Form onFinish={this.submit}>
                            {this.state.showError &&
                                <Form.Item>
                                    <Alert showIcon message="用户名或密码错误！" type="error" />
                                </Form.Item>
                            }
                            <Form.Item name="admin_user" rules={[{ required: true, message: '请输入用户名!' }]}>
                                <Input autoComplete="off" placeholder="用户名" size="large" />
                            </Form.Item>
                            <Form.Item name="admin_pwd" rules={[{ required: true, message: '请输入密码!' }]}>
                                <Input.Password autoComplete="off" placeholder="密码" size="large" />
                            </Form.Item>
                            <Form.Item name="check_code" rules={[{ required: true, message: '请输入验证码!' }]} style={{ width: "40%", float: "left" }}>
                                <Input autoComplete="off" placeholder="验证码" size="large" />
                            </Form.Item>
                            <div style={{ marginLeft: "50%", padding: "4px", textAlign: "center", backgroundColor: "#EECC77", color: "#AA33EE", fontSize: "20px", fontStyle: "oblique", fontWeight: "bolder" }} onClick={() => this.checkRefresh()}>
                                {this.state.num1} + {this.state.num2} = ？
                            </div>
                            <Form.Item style={{ clear: "both" }}>
                                <Button type="primary" htmlType="submit" block size="large">提交</Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Content>
            </Layout>
        );
        return (
            element
        )
    }
}