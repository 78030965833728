import { Button, Col, Divider, Form, Input, Layout, Select, Space, Switch, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { Children } from 'react';
import RequestComponent from '../../compontents/common/RequestComponent';
import { timeToDateTime } from '../../compontents/common/DateComponent';
import { formlayout, formlayoutModal, formlayoutSearch, getRowClassName, searchFormCol } from '../../global';
import {ReloadOutlined }  from '@ant-design/icons';

export default class LogList extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        // 查询参数
        this.queryParam = {
            page: 1,
            content: "",
            admin_id: ''
        };
        this.state = {
            data: [],
            admin:[],
            pagination: {
                current: 1,
                position: ['bottomLeft'],
                hideOnSinglePage: true,
                total: 0,
                showQuickJumper: true,
                showSizeChanger:false,
                pageSize: 20,
                showTotal: (total) => {return '共' + total + '条'}
                // onChange: this.handleTableChange
            }
        }
    }

    componentDidMount() {
        this.queryData()
    }

    // 查询数据
    queryData = (data) => {
        if (data == undefined) {
            data = this.queryParam;
        }
        RequestComponent('log/list', {
            method: "GET",
            data: data
        }).then(r => {
            this.state.pagination.total = r.data.data.count
            this.state.data = r.data.data.list
            this.state.admin = r.data.data.admin
            this.setState({
                data: r.data.data.list,
            })
        });
    }

    //切换分页
    handleTableChange = (page, filter, sort) => {
        if (page.current != this.state.pagination.current) {
            this.state.pagination.current = page.current;
            // this.state.queryParam.page = page.current
            let data = this.formRef.current.getFieldsValue();
            data.page = page.current;
            this.queryData(data)
        }
    }

    // 表单提交
    submit = (v) => {
        this.queryData(v)
    }

    // 重置查询
    reload = () => {
        this.queryData();
        this.onReset();
    }

    // 重置表单
    onReset = (v) => {
        this.formRef.current.setFieldsValue(this.queryParam);
    }

    render() {
        let columns = [
            {
                title: 'ID',
                dataIndex: 'Id',
                sorter: true,
            },
            {
                title: '操作人',
                dataIndex: 'AdminUser',
            },
            {
                title: '操作时间',
                dataIndex: 'CreateTime',
                sorter: true,
                render: (v) => {
                    return timeToDateTime(v)
                }
            },
            {
                title: '操作内容',
                dataIndex: 'Content',
            }
        ];
        let html = (
            <Layout.Content>
                <Form  name="horizontal_login" layout="inline" ref={this.formRef} onFinish={this.submit} initialValues={this.queryParam}>
                    <Form.Item label="操作内容" name="content">
                        <Input placeholder="操作内容" />
                    </Form.Item>
                    <Form.Item label="操作人" name="admin_id">
                        <Select showSearch style={{width: 120,}}>
                            {this.state.admin.map((i, e) => (
                                <Select.Option key={i.AdminId} value={i.AdminId}>{i.AdminUser}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item hidden name='page'>
                        <Input />
                    </Form.Item>
                    <Form.Item >
                        <Space size={10}>
                            <Button htmlType="submit" type="primary">查询</Button>
                            <Button htmlType="reset" type="default" onClick={this.onReset}>清空</Button>
                            <Button shape="circle" icon={<ReloadOutlined />} onClick={this.reload}></Button>
                        </Space>
                    </Form.Item>
                </Form>
                <Divider />
                <Table rowClassName={getRowClassName} rowKey="Id" columns={columns} dataSource={this.state.data} scroll={{ x: 'max-content' }} onChange={this.handleTableChange} pagination={this.state.pagination} size="small"></Table>
            </Layout.Content>
        );
        return (
            html
        )
    }
}