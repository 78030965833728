import React from 'react';
import { Button, Form, Input, Layout, PageHeader, Select, Card, message, Divider } from 'antd';
import { formlayout } from '../../../global';
import RequestComponent, { GetQuery } from '../../../compontents/common/RequestComponent';
import {ReloadOutlined }  from '@ant-design/icons';


export default class Config extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.initialValues =  this.initialValues = this.getInitialValues();

        this.state = {
            data: [],
        }
    }

    getInitialValues() {
        return {
            phone: '',
            data1: 0,
            data2: 0,
            data3: 0,
            data4: 0,
            data5: 0,
            data6: 0,
            data7: 0,
            data8: 0,
            data9: 0,
            data10: 0,
            data11: 0,
            data12: 0,
            data13: 0,
            data14: 0,
            data15: 0,
            tourist_second: 10,
            tourist_range: '-10,10',
            tab_second: 30,
            introduction: '',
            development: ''
        };
    }

    componentDidMount = () => {
        this.queryData();
    }

    queryData = () => {
        RequestComponent('operate/config', {
            method: "GET"
        }).then(r => {
            if (r.data.data != null) {
                this.formRef.current.setFieldsValue({
                    phone: r.data.data.phone ? r.data.data.phone : '',
                    data1: r.data.data.data1 ? r.data.data.data1 : 0,
                    data2: r.data.data.data2 ? r.data.data.data2 : 0,
                    data3: r.data.data.data3 ? r.data.data.data3 : 0,
                    data4: r.data.data.data4 ? r.data.data.data4 : 0,
                    data5: r.data.data.data5 ? r.data.data.data5 : 0,
                    data6: r.data.data.data6 ? r.data.data.data6 : 0,
                    data7: r.data.data.data7 ? r.data.data.data7 : 0,
                    data8: r.data.data.data8 ? r.data.data.data8 : 0,
                    data9: r.data.data.data9 ? r.data.data.data9 : 0,
                    data10: r.data.data.data10 ? r.data.data.data10 : 0,
                    data11: r.data.data.data11 ? r.data.data.data11 : 0,
                    data12: r.data.data.data12 ? r.data.data.data12 : 0,
                    data13: r.data.data.data13 ? r.data.data.data13 : 0,
                    data14: r.data.data.data14 ? r.data.data.data14 : 0,
                    data15: r.data.data.data15 ? r.data.data.data15 : 0,
                    tourist_second: r.data.data.tourist_second ? r.data.data.tourist_second : 0,
                    tourist_range: r.data.data.tourist_range ? r.data.data.tourist_range : '-10,10',
                    tab_second: r.data.data.tab_second ? r.data.data.tab_second : 30,
                    introduction: r.data.data.introduction ? r.data.data.introduction : '',
                    development: r.data.data.development ? r.data.data.development : '',
                });
            }
        });
    }

    // 重置查询
    reload = () => {
        this.queryData();
    }

    submit = (v) => {
        RequestComponent('operate/config', {
            data: v
        }).then(r => {
            if (r.data.code == 0) {
                RequestComponent('log/add', {
                    data: {
                        content: '修改运营配置'
                    }
                });
                message.success({
                    content: '提交成功',
                    style: {
                        marginTop: '10vh',
                    },
                });
            }
        })
    }

    render() {
        let html = (
            <Layout.Content>
                    <Button shape="circle" icon={<ReloadOutlined />} style={{marginBottom: 20}} onClick={this.reload}></Button>
                    <Form {...formlayout.form} onFinish={this.submit} ref={this.formRef} initialValues={this.initialValues}>
                        <Divider>基本配置</Divider>
                        <Form.Item label="联系电话" name="phone"  >
                            <Input autoComplete="off" addonAfter="多个号码用英文,号隔开" />
                        </Form.Item>
                        <Divider>智慧大屏数据配置</Divider>
                        <Form.Item label="A级景点" name="data1"  >
                            <Input autoComplete="off" addonAfter="个" />
                        </Form.Item>
                        <Form.Item label="景点" name="data2"  >
                            <Input autoComplete="off" addonAfter="个" />
                        </Form.Item>
                        <Form.Item label="乡村旅游点" name="data3"  >
                            <Input autoComplete="off" addonAfter="个" />
                        </Form.Item>
                        <Form.Item label="酒店" name="data4"  >
                            <Input autoComplete="off" addonAfter="个" />
                        </Form.Item>
                        <Form.Item label="民宿" name="data5"  >
                            <Input autoComplete="off" addonAfter="个" />
                        </Form.Item>
                        <Form.Item label="特色餐饮" name="data6"  >
                            <Input autoComplete="off" addonAfter="个" />
                        </Form.Item>
                        <Form.Item label="停车场" name="data7"  >
                            <Input autoComplete="off" addonAfter="个" />
                        </Form.Item>
                        <Form.Item label="五星房车露营地" name="data15"  >
                            <Input autoComplete="off" addonAfter="个" />
                        </Form.Item>
                        <Form.Item label="旅游厕所" name="data8"  >
                            <Input autoComplete="off" addonAfter="个" />
                        </Form.Item>
                        <Form.Item label="实时游客" name="data9"  >
                            <Input autoComplete="off" addonAfter="个" />
                        </Form.Item>
                        <Form.Item label="一周游客" name="data10"  >
                            <Input autoComplete="off" addonAfter="个" />
                        </Form.Item>
                        <Form.Item label="年累计游客" name="data11"  >
                            <Input autoComplete="off" addonAfter="个" />
                        </Form.Item>
                        <Form.Item label="男性人数" name="data12"  >
                            <Input autoComplete="off" addonAfter="个" />
                        </Form.Item>
                        <Form.Item label="女性人数" name="data13"  >
                            <Input autoComplete="off" addonAfter="个" />
                        </Form.Item>
                        <Form.Item label="预定购票" name="data14"  >
                            <Input autoComplete="off" addonAfter="个" />
                        </Form.Item>
                        <Form.Item label="游客刷新频率" name="tourist_second"  >
                            <Input autoComplete="off" addonAfter="秒, 每多少秒刷新一次" />
                        </Form.Item>
                        <Form.Item label="游客刷新范围" name="tourist_range"  >
                            <Input autoComplete="off" addonAfter="例如-10,100: 表示每次刷新后数量在-10到100之间取一个数。注意：,号一定要英文的" />
                        </Form.Item>
                        <Form.Item label="大屏切换频率" name="tab_second"  >
                            <Input autoComplete="off" addonAfter="秒, 每多少秒切换一次" />
                        </Form.Item>
                        <Divider>全景大屏配置</Divider>
                        <Form.Item label="度假区简介" name="introduction"  >
                            <Input.TextArea autoComplete="off" rows={5} />
                        </Form.Item>
                        <Form.Item label="建设情况" name="development"  >
                            <Input.TextArea autoComplete="off" rows={5} />
                        </Form.Item>
                        <Form.Item wrapperCol={formlayout.button}>
                            <Button htmlType="submit" type="primary">提交</Button>
                        </Form.Item>
                    </Form>
            </Layout.Content>
        );
        return (
            html
        )
    }
}