import { Button, Col, Divider, Form, Input, Layout, message, Select, Space, Switch, Table } from 'antd';
import React, { Children } from 'react';
import RequestComponent from '../../compontents/common/RequestComponent';
import { formlayout, formlayoutSearch, searchFormCol } from '../../global';

export default class LogList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        }
        this.formRef = React.createRef();
    }

    componentDidMount = () => {
        RequestComponent('system/site', {
            method: "GET"
        }).then(r => {
            if (r.data.data != null) {
                let json = JSON.parse(r.data.data)
                this.formRef.current.setFieldsValue({
                    site_name: json.SiteName,
                });
            }
        })
    }

    submit = (v) => {
        RequestComponent('system/site', {
            data: v
        }).then(r => {
            if (r.data.code == 0) {
                RequestComponent('log/add', {
                    data: {
                        content: '站点设置'
                    }
                });
                message.success({
                    content: '提交成功',
                    style: {
                        marginTop: '10vh',
                    },
                });
            }
        })
    }

    render() {
        let html = (
            <Form {...formlayout.form} onFinish={this.submit} ref={this.formRef} >
                <Form.Item label="站点名称" name="site_name" >
                    <Input autoComplete="off"></Input>
                </Form.Item>
                <Form.Item wrapperCol={formlayout.button}>
                    <Button htmlType="submit" type="primary">提交</Button>
                </Form.Item>
            </Form>
        );
        return (
            html
        )
    }
}