/**
 * 表单响应式配置
 */
export const formlayout = {
    form: {
        labelCol: {
            sm: { span: 7 },
            md: { span: 4 },
            lg: { span: 2 }
        },
        wrapperCol: {
            span: 24,
        },
    },
    button: {
        sm: { offset: 7 },
        md: { offset: 4 },
        lg: { offset: 2 }
    }
};
/**
 * 表单响应式配置
 */
export const formlayoutModal = {
    form: {
        labelCol: {
            sm: { span: 7 },
            md: { span: 4 },
            lg: { span: 5 }
        },
        wrapperCol: {
            span: 24,
        },
    },
    button: {
        sm: { offset: 7 },
        md: { offset: 4 },
        lg: { offset: 5 }
    }
};
/**
 * 表格奇偶行class
 */
export const getRowClassName = (record, index) => {
    let className = '';
    className = index % 2 === 0 ? "table-oddRow" : "table-evenRow";
    return className
}
/**
 * 搜索区域表单响应式配置
 */
export const formlayoutSearch = {
    form: {
        labelCol: {
            sm: { span: 7 },
            md: { span: 4 },
            lg: { span: 8 }
        },
        wrapperCol: {
            span: 24,
        },
    },
    button: {
        sm: { offset: 7 },
        md: { offset: 4 },
        lg: { offset: 2 }
    }
};
/**
 * 搜索区域响应配置 Col xs={24} sm={24} md={8} xl={6} xxl={4} >
 */
export const searchFormCol = {
    xs: 24,
    sm: 24,
    md: 8,
    xl: 6,
    xxl: 3
};

export const segments = window.location.pathname.split('/');

export const ossUrl = "https://kkk.hongyangtour.com/";
export const ossUrlInternal = "https://hongyang-trip.oss-cn-hangzhou-internal.aliyuncs.com/";
// export const apiUrl = "http://127.0.0.1:8612/";
export const apiUrl = "http://hongyang-backend-server.hongyangtour.com/";